// src/componentes/Rodape.js
import React from 'react';
import './Rodape.css';

const Rodape = () => {
  return (
    <footer className="rodape-unique">
      <div className="rodape-container-unique">
        <p className="rodape-text-unique">
          © 2024. Todos os direitos reservados.
          <a href="/politica-de-publicidade" className="rodape-link-unique">Política de Publicidade</a>
        </p>
      </div>
    </footer>
  );
}

export default Rodape;
