import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; 
import { login } from '../auth/auth'; 
import './Login.css';
import logo from '../imagens/03logo_menu_login.webp';
import Rodape from '../componentes/Rodape'; 
import { DateTime } from 'luxon'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState('');
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();

  // Função auxiliar para parsing de datas em múltiplos formatos
  const parseData = (dataString) => {
    // Tenta analisar como ISO 8601
    let data = DateTime.fromISO(dataString, { zone: 'America/Sao_Paulo' });
    if (data.isValid) return data;
    
    // Tenta analisar como SQL (yyyy-MM-dd HH:mm:ss)
    data = DateTime.fromSQL(dataString, { zone: 'America/Sao_Paulo' });
    if (data.isValid) return data;
    
    // Tenta analisar apenas a data (yyyy-MM-dd)
    data = DateTime.fromFormat(dataString, 'yyyy-MM-dd', { zone: 'America/Sao_Paulo' });
    return data; // retorna mesmo que não seja válido, para manter coerência
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (loading) return;

    setLoading(true);
    setErro('');

    try {
      const { data: authResponse, error: erroAuth } = await supabase.auth.signInWithPassword({
        email,
        password: senha,
      });

      if (erroAuth) {
        setErro('Email ou senha incorretos.');
        setLoading(false);
        return;
      }

      console.log('Autenticação bem-sucedida. Verificando informações adicionais do usuário...');

      // Pega os dados do usuário
      const { data: usuarioInfo, error: erroUsuarioInfo } = await supabase
        .from('usuarios')
        .select('user_id, email, nome, plano_id, data_renovacao_palavras')
        .eq('email', email)
        .single();

      if (erroUsuarioInfo || !usuarioInfo) {
        setErro('Erro ao buscar informações do usuário.');
        setLoading(false);
        return;
      }

      const userId = usuarioInfo.user_id;
      const planoId = usuarioInfo.plano_id;

      // Verificação do usuário gratuito
      if (planoId === 1) { 
        // Parsing da data de renovação (utilizando nossa função de parsing)
        const dataRenovacaoPalavras = parseData(usuarioInfo.data_renovacao_palavras);

        // Verifica se a data é válida
        if (!dataRenovacaoPalavras.isValid) {
          setErro('Data de renovação inválida. Por favor, contate o suporte.');
          setLoading(false);
          return;
        }

        // Calcula a diferença em dias entre agora e a data de renovação
        const agora = DateTime.now().setZone('America/Sao_Paulo'); 
        const duracao = agora.diff(dataRenovacaoPalavras, 'days').days;

        if (duracao > 30) {
          setErro('Seu período de teste de 30 dias expirou. Por favor, adquira um plano pago para continuar usando a plataforma.');
          setLoading(false);
          return;
        }
      }

      // Verificação do usuário pago (plano básico ou premium)
      if (planoId !== 1) {
        const { data: historicoCobranca, error: erroHistorico } = await supabase
          .from('historico_cobranca')
          .select('status_cobranca, created_at, data_expiracao, produto_id')
          .eq('usuario_id', userId)
          .order('created_at', { ascending: false });

        if (erroHistorico || !historicoCobranca || historicoCobranca.length === 0) {
          setErro('Erro ao verificar sua assinatura.');
          setLoading(false);
          return;
        }

        // Agrupar cobranças por produto e pegar a mais recente de cada
        const ultimaCobrancaPorProduto = {};

        historicoCobranca.forEach((registro) => {
          const { produto_id, created_at } = registro;
          // Se ainda não temos uma cobrança para este produto, ou se esta é mais recente
          const saved = ultimaCobrancaPorProduto[produto_id];
          if (!saved) {
            ultimaCobrancaPorProduto[produto_id] = registro;
          } else {
            const currDate = parseData(registro.created_at);
            const savedDate = parseData(saved.created_at);
            if (currDate.isValid && savedDate.isValid && currDate > savedDate) {
              ultimaCobrancaPorProduto[produto_id] = registro;
            }
          }
        });

        // Extrair as últimas cobranças
        const ultimasCobrancas = Object.values(ultimaCobrancaPorProduto);

        const agora = DateTime.now().setZone('America/Sao_Paulo');

        // Verificar se pelo menos uma das últimas cobranças possui status válido ou expirado
        const statusValido = ultimasCobrancas.some((registro) => {
          const statusCobranca = (registro.status_cobranca || '').toLowerCase();
          const dataExpiracao = parseData(registro.data_expiracao);

          // Verifica se o status é pago ou renovado, mas a data de expiração ainda não foi atingida
          return (
            (['pago', 'assinatura renovada'].includes(statusCobranca) && agora <= dataExpiracao) || 
            (statusCobranca === 'assinatura cancelada' && agora <= dataExpiracao)
          );
        });

        if (!statusValido) {
          setErro('Nenhum dos seus produtos está com status ativo ou válido.');
          setLoading(false);
          return;
        }
      }

      // Login bem-sucedido, salvar sessão e redirecionar
      await login(
        authResponse.session.access_token, 
        usuarioInfo.email, 
        usuarioInfo.nome, 
        usuarioInfo.user_id
      );

      console.log('Login bem-sucedido, redirecionando para o menu...');
      navigate('/menu'); 

    } catch (error) {
      console.error('Erro ao realizar login:', error);
      setErro('Ocorreu um erro ao tentar fazer login. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    const passwordInput = document.getElementById('password');
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  };

  return (
     <div className="login-container">
      <div className="form-container">
        {/* Substituindo o título pelo logo */}
        <div className="logo-wrapper">
          <img src={logo} alt="O Caminho para a Prosperidade Digital" className="login-logo" />
        </div>
        <p className="gradient-text"></p>
        <h2 className="login-title">Bem-vindo(a) ao início de uma nova fase!</h2>
        <p className="login-page-description">
          A sua jornada para uma vida próspera começa aqui. Com o conhecimento certo, 
          as ferramentas adequadas e o apoio que você merece, vamos transformar obstáculos 
          em oportunidades e tornar seus sonhos realidade. Vamos juntos nessa?
        </p>
        <form onSubmit={handleLogin}>
          <div className="input-container">
            <i className="fas fa-envelope"></i>
            <input
              type="email"
              placeholder="E-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-container">
            <i className="fas fa-lock"></i>
            <input
              type="password"
              id="password"
              placeholder="Senha"
              value={senha}
              onChange={(e) => setSenha(e.target.value)}
              required
              className="password-input"
            />
            <i className="fas fa-eye toggle-password" onClick={togglePasswordVisibility}></i>
          </div>
          {erro && <p className="error">{erro}</p>}
          <button type="submit" disabled={loading}>
            {loading ? 'Entrando...' : 'Entrar'}
          </button>
        </form>
        <div className="login-button-group">
          <button onClick={() => navigate('/cadastro')}>Cadastre-se</button>
          <button onClick={() => navigate('/recuperar-senha')}>Recuperar Senha</button>
        </div>
      </div>
      <button
        className="loginScreen-button-youtube"
        onClick={() => window.open('https://www.youtube.com/@OCAMINHOPARAPROSPERIDADE/L', '_blank')}
      >
        <i className="fab fa-youtube" style={{ marginRight: '8px' }}></i>
        Visite Nosso Canal no YouTube
      </button>
      <Rodape />
    </div>
  );
};

export default Login;
