import ReactPixel from "react-facebook-pixel";

// Substitua pelo seu ID do Pixel do Facebook
const PIXEL_ID = "1002052974883881";

export const initPixel = () => {
  ReactPixel.init(PIXEL_ID);
};

// Evento de PageView
export const trackPageView = () => {
  ReactPixel.pageView();
};
