import ReactGA from "react-ga4";

// Substitua pelo seu ID do Google Analytics
const GA_TRACKING_ID = "G-SDP0MMBBDL";

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const trackPageView = (path) => {
  ReactGA.send({ hitType: "pageview", page: path });
};
