import React, { useEffect, useState } from 'react';
import { logout, isAuthenticated, getUserEmail, getUserName } from '../../auth/auth';
import { useNavigate, Link } from 'react-router-dom';
import './Menu.css';
import logo from '../../imagens/logo_menu_login.webp';
import MenuVertical from './MenuVertical';
import { supabase } from '../../supabaseClient';

// Importando os arquivos necessários para a notificação
import welcomeSound from '../../assets/sounds/welcome.MP3';
import mensagens from '../../assets/mensagens.json';

// Importando as imagens para o menu
import minhaContaImg from '../../imagens/minha-conta.webp';
import suporteClienteImg from '../../imagens/suporte-cliente.webp';
import ferramentasImg from '../../imagens/ferramentas.webp';
import treinamentoImg from '../../imagens/pensa.webp';

// Definição das categorias e suas condições de acesso
const categories = [
  {
    title: 'Ferramentas para Prosperar no Digital',
    items: [
      { id: 4, img: ferramentasImg, alt: 'Ferramentas', link: '/ferramentas' },
    ],
    // Se o produto id=4 está na lista ou se for planoId = 1 (grátis), libera
    access: (produtos, planoId) => produtos.some(p => p.id === 4) || planoId === 1,
  },
  {
    title: 'Projeto Meu Primeiro Salário Sem Sair de Casa',
    items: [
      { id: 1, img: treinamentoImg, alt: 'Treinamento', link: '/treinamentos' },
    ],
    access: (produtos) => produtos.some(p => p.id === 1),
  },
  {
    title: 'Administração',
    items: [
      { id: 'minha-conta', img: minhaContaImg, alt: 'Minha Conta', link: '/minha-conta' },
      { id: 'suporte-cliente', img: suporteClienteImg, alt: 'Suporte ao Cliente', link: '/suporte' },
    ],
    // Sempre liberado (access retorna true)
    access: () => true,
  },
];

const Menu = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [userName, setUserName] = useState('');
  const [randomMessage, setRandomMessage] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [produtos, setProdutos] = useState([]);  // lista de produtos comprados
  const [planoId, setPlanoId] = useState(null);
  const [loading, setLoading] = useState(true);

  // Novo: para armazenar o status atual (última linha) de cada produto
  const [statusProdutos, setStatusProdutos] = useState({});

  useEffect(() => {
    if (isAuthenticated()) {
      const email = getUserEmail();
      const name = getUserName();
      setUserEmail(email);
      setUserName(name);

      // Verifica se produtos/plano já estão em localStorage
      const storedProdutos = localStorage.getItem('produtosComprados');
      const storedPlanoId = localStorage.getItem('planoId');

      if (storedProdutos) {
        setProdutos(JSON.parse(storedProdutos));
      } else {
        fetchProdutosDoBanco();
      }

      if (storedPlanoId) {
        setPlanoId(parseInt(storedPlanoId));
      } else {
        fetchPlanoIdDoBanco();
      }

      // Toca mensagem de boas-vindas se passaram mais de 10 minutos desde a última exibição
      const lastLoginTime = localStorage.getItem('lastLoginTime');
      const currentTime = new Date().getTime();
      const tenMinutes = 10 * 60 * 1000;

      if (!lastLoginTime || currentTime - lastLoginTime > tenMinutes) {
        localStorage.setItem('lastLoginTime', currentTime);
        setShowMessage(true);
        const randomIndex = Math.floor(Math.random() * mensagens.length);
        setRandomMessage(mensagens[randomIndex] || 'Você nasceu para prosperar!🚀');
      }

      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    // Quando tivermos os produtos, buscar status de cobrança para cada produto
    if (produtos && produtos.length > 0) {
      fetchStatusDosProdutos();
    }
  // eslint-disable-next-line
  }, [produtos]);

  // Função para buscar produtos do banco de dados se não estiverem no localStorage
  const fetchProdutosDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const { data: compras, error } = await supabase
        .from('compras')
        .select('produto_id')
        .eq('usuario_id', userId);

      if (error) {
        console.error('Erro ao buscar produtos no banco de dados:', error);
      } else if (compras && compras.length > 0) {
        const produtosDisponiveis = compras.map(c => ({ id: c.produto_id }));
        localStorage.setItem('produtosComprados', JSON.stringify(produtosDisponiveis));
        setProdutos(produtosDisponiveis);
      }
    } catch (error) {
      console.error('Erro ao consultar produtos do banco:', error);
    }
  };

  // Função para buscar o plano_id do usuário na tabela usuarios
  const fetchPlanoIdDoBanco = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const { data: usuario, error } = await supabase
        .from('usuarios')
        .select('plano_id')
        .eq('user_id', userId)
        .single();

      if (error) {
        console.error('Erro ao buscar plano do usuário no banco de dados:', error);
      } else if (usuario) {
        localStorage.setItem('planoId', usuario.plano_id);
        setPlanoId(usuario.plano_id);
      }
    } catch (error) {
      console.error('Erro ao buscar plano no banco:', error);
    }
  };

  // ==============================
  // FUNÇÃO NOVA: busca historico_cobranca e guarda status
  // ==============================
  const fetchStatusDosProdutos = async () => {
    try {
      const userId = localStorage.getItem('userId');
      // Pega todo o histórico de cobranças do usuário
      const { data: historicos, error } = await supabase
        .from('historico_cobranca')
        .select('status_cobranca, produto_id, created_at')
        .eq('usuario_id', userId)
        .order('created_at', { ascending: false });

      if (error) {
        console.error('Erro ao buscar histórico de cobrança:', error);
        return;
      }
      if (!historicos || historicos.length === 0) {
        // Se não há nada no histórico, não faz nada
        return;
      }

      // Agrupa por produto_id, pegando somente a linha mais recente
      const ultimaPorProduto = {};
      historicos.forEach((registro) => {
        const { produto_id, created_at } = registro;
        if (!ultimaPorProduto[produto_id]) {
          ultimaPorProduto[produto_id] = registro; 
        } else {
          const current = new Date(registro.created_at).getTime();
          const saved = new Date(ultimaPorProduto[produto_id].created_at).getTime();
          if (current > saved) {
            ultimaPorProduto[produto_id] = registro;
          }
        }
      });

      // Monta objeto com status final de cada produto
      const statusMap = {};
      produtos.forEach(prod => {
        const ult = ultimaPorProduto[prod.id];
        if (ult) {
          // normalizamos para minúsculo
          statusMap[prod.id] = (ult.status_cobranca || '').toLowerCase();
        }
      });

      setStatusProdutos(statusMap);
    } catch (err) {
      console.error('Erro ao processar status dos produtos:', err);
    }
  };

  const playWelcomeSound = () => {
    const audio = new Audio(welcomeSound);
    audio.play().catch(error => console.error('Erro ao tocar o som:', error));
  };

  useEffect(() => {
    if (showMessage) {
      playWelcomeSound();
    }
  }, [showMessage]);

  const handleLogout = () => {
    logout();
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  // Função para verificar acesso a uma categoria
  const hasAccess = (category) => {
    return category.access(produtos, planoId);
  };

  // Verifica se usuário possui determinado produto
  const userHasProduct = (produtoId) => {
    return produtos.some((p) => p.id === produtoId);
  };

  // Função para renderizar o botão correto, dependendo do status
  const renderButton = (produtoId, link) => {
    // Se o usuário não tiver o produto, bloqueamos e exibimos a opção de comprar
    if (!userHasProduct(produtoId)) {
      return (
        <>
          <button className="btn-unique" disabled>Bloqueado</button>
          <div className="overlay-blocked">Bloqueado</div>
          <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
        </>
      );
    }

    // Se o usuário tem o produto, verificamos se a última linha do histórico é "chargeback" ou "reembolsado"
    const status = statusProdutos[produtoId] || '';

    if (status === 'chargeback' || status === 'reembolsado') {
      // Se for chargeback/reembolsado => exibimos o botão de Reativar Assinatura
      return (
        <Link to="/reativar-assinatura" className="no-underline-unique">
          <button className="btn-unique">
            Reativar Assinatura
          </button>
        </Link>
      );
    } else {
      // Caso contrário (pago, cancelado, ou outro status) => mostra o botão normal de acesso
      return (
        <Link to={link} className="no-underline-unique">
          <button className="btn-unique">Acesse aqui</button>
        </Link>
      );
    }
  };

  return (
    <div className="menu-container-unique">
      <MenuVertical />
      {loading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {showMessage && (
            <div className="message-popup-unique">
              <h2>
                Seja muito bem-vindo(a), <span className="user-name-unique">{userName}</span>!
              </h2>
              <p>
                {randomMessage} <span className="highlight-unique">Esta mensagem é para você!</span>
              </p>
              <button className="close-btn-unique" onClick={handleCloseMessage}>Fechar</button>
              <p className="footer-message-unique">Você nasceu para prosperar!🚀</p>
            </div>
          )}

          <div className="header-unique">
            <img src={logo} alt="Logo" className="menu-logo-unique" />
            <div className="user-info-unique">
              <span>{userName}</span>
              <button className="logout002-button" onClick={handleLogout}>Sair</button>
            </div>
          </div>

          <div className="content-unique">
            <h1 className="title-gradient-unique">O Caminho para a Prosperidade Digital</h1>
            <p className="description-unique">
              "Bem-vindo! Aqui você encontra as ferramentas essenciais para iniciar sua jornada digital e alcançar a prosperidade."
            </p>

            {categories.map((category, index) => (
              <div key={index}>
                <div className="category-title-unique">{category.title}</div>
                <div className="menu-items-unique">
                  {category.items.map((item, idx) => {
                    // Verifica se a categoria está liberada ou não (planoId, etc.)
                    const access = hasAccess(category);
                    return (
                      <div key={idx} className={`menu-item-unique ${!access ? 'blocked' : ''}`}>
                        <img src={item.img} alt={item.alt} className="menu-img" />

                        {/* Se a categoria for bloqueada pelo plano, exibe bloqueado */}
                        {!access ? (
                          <>
                            <button className="btn-unique" disabled>Bloqueado</button>
                            <div className="overlay-blocked">Bloqueado</div>
                            <a href="/comprar-produto" className="buy-link">Comprar Produto</a>
                          </>
                        ) : (
                          // Se a categoria não é bloqueada, mas o produto em si pode estar em chargeback/reembolsado
                          <>
                            {renderButton(item.id, item.link)}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Menu;
