import React, { useState, useEffect } from 'react';
import './Presente.css';
import logo from '../../imagens/logo_menu_login.webp';

/**
 * IMPORT DO SUPABASE
 */
import { supabase } from '../../supabaseClient';

const Presente = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    nome: '',
    email: '',
    whatsapp: '',
    idade: '',
    mensagem: ''
  });
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  /**
   * NOVO STATE para controlar a exibição da notificação dentro do modal
   */
  const [showNotification, setShowNotification] = useState(false);

  /**
   * 1) Ao carregar o componente, verifica se já existe `nome` e `email` no Local Storage.
   *    Se sim, redireciona imediatamente para "/#/Contigencia".
   */
  useEffect(() => {
    const nomeStorage = localStorage.getItem('nome');
    const emailStorage = localStorage.getItem('email');

    if (nomeStorage && emailStorage) {
      window.location.hash = '/Contigencia';
    }
  }, []);

  /**
   * 2) Cria um contador regressivo até 31/12/2024
   */
  useEffect(() => {
    const timer = setInterval(() => {
      const endDate = new Date('2025-01-02T23:59:59');
      const now = new Date();
      const difference = endDate - now;

      if (difference > 0) {
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / 1000 / 60) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  /**
   * 3) Atualiza o estado (nome, email, etc.) ao alterar qualquer campo do formulário
   */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  /**
   * 4) Ao submeter o formulário, insere os dados no Supabase,
   *    salva nome/email no Local Storage e exibe a notificação.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Inserção no Supabase
      const { data, error } = await supabase
        .from('presente_de_natal_youtube')
        .insert({
          nome: formData.nome,
          email: formData.email,
          whatsapp: formData.whatsapp,
          idade: formData.idade,
          mensagem: formData.mensagem
        });

      if (error) {
        console.error('Erro ao inserir no banco de dados:', error);
        alert('Ocorreu um erro ao enviar seus dados. Tente novamente.');
        return;
      }

      // Se deu tudo certo, salvar nome/email no LocalStorage
      localStorage.setItem('nome', formData.nome);
      localStorage.setItem('email', formData.email);

      // Mostrar a notificação
      setShowNotification(true);

      // Após 2 segundos, redirecionar
      setTimeout(() => {
        window.location.hash = '/Contigencia';
      }, 2000);

    } catch (err) {
      console.error('Erro inesperado:', err);
      alert('Ocorreu um erro ao enviar seus dados. Tente novamente.');
    }
  };

  /**
   * Renderização do componente
   */
  return (
    <div className="presente-container">
      <div className="presente-header">
        <div className="presente-logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>

      <div className="presente-date-info">
        <p>⚠️ Disponível somente até 02 de Janeiro de 2025 às 23:59</p>
      </div>

      <main className="presente-main">
        <h1 className="presente-title">🎁 Meu Presente de Natal para Você!</h1>
        
        <h2 className="presente-subtitle">
          Como parte do Desafio: EU Transbordo Prosperidade, estou te dando acesso a um treinamento exclusivo:
          <br />
          Contingência no Facebook - Do Básico ao Avançado.
        </h2>

        {/* Contagem regressiva */}
        <div className="presente-countdown">
          <p className="presente-urgency">⚠️ Atenção! Este presente estará disponível apenas até:</p>
          <div className="presente-timer">
            <div className="timer-item">
              <div className="timer-number">{timeLeft.days}</div>
              <div className="timer-label">Dias</div>
            </div>
            <div className="timer-item">
              <div className="timer-number">{timeLeft.hours}</div>
              <div className="timer-label">Horas</div>
            </div>
            <div className="timer-item">
              <div className="timer-number">{timeLeft.minutes}</div>
              <div className="timer-label">Minutos</div>
            </div>
            <div className="timer-item">
              <div className="timer-number">{timeLeft.seconds}</div>
              <div className="timer-label">Segundos</div>
            </div>
          </div>
        </div>

        <section className="presente-section">
          <h3>Por que estou te dando esse presente?</h3>
          <p>
            Porque eu acredito que você nasceu para prosperar! No movimento #EuTransbordoProsperidade,
            acreditamos que o conhecimento é como uma semente: ao ser cultivado, ele cresce e transforma
            sua vida e a vida de quem está ao seu redor.
          </p>
          <p>
            Este treinamento exclusivo é o meu presente para você, porque quero que você esteja pronto para
            proteger suas campanhas e criar perfis no Facebook de maneira ilimitada, conquistando resultados
            que vão mudar o rumo da sua jornada. Este é o seu momento de crescer, alcançar a liberdade que
            você merece e ajudar outras pessoas a fazerem o mesmo. Juntos, vamos prosperar e espalhar transformação
            por onde passarmos! 🌟✨
          </p>
        </section>

        <button
          className="presente-cta-button"
          onClick={() => setShowModal(true)}
        >
          📌 Quero Receber Meu Presente Agora!
        </button>

        <section className="presente-final-message">
          <h3>🌟 Esse é o momento de transbordar prosperidade na sua vida e na vida de outras pessoas!</h3>
          <p>
            Chegou a hora de dar o próximo passo em direção ao seu sucesso. Com este treinamento exclusivo, você terá
            acesso a conhecimentos e ferramentas que vão transformar sua jornada no marketing digital. Não perca
            esta oportunidade única de elevar seu negócio a um novo patamar!
          </p>
        </section>
      </main>

      <div className="presente-date-info-bottom">
        <p>⚠️ Disponível somente até 02 de Janeiro de 2025 às 23:59</p>
      </div>

      {showModal && (
        <div className="presente-modal-overlay">
          <div className="presente-modal">
            <button
              className="presente-modal-close"
              onClick={() => setShowModal(false)}
            >
              ×
            </button>
            <h3>🎉 Este presente é especial para você! Preencha os detalhes abaixo para eu entregar diretamente.</h3>
            <form onSubmit={handleSubmit} className="presente-form">
              <div className="presente-form-group">
                <input
                  type="text"
                  name="nome"
                  placeholder="Seu Nome"
                  required
                  value={formData.nome}
                  onChange={handleInputChange}
                />
              </div>
              <div className="presente-form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Seu E-mail"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="presente-form-group">
                <input
                  type="tel"
                  name="whatsapp"
                  placeholder="Seu WhatsApp"
                  required
                  value={formData.whatsapp}
                  onChange={handleInputChange}
                />
              </div>
              <div className="presente-form-group">
                <input
                  type="number"
                  name="idade"
                  placeholder="Sua Idade"
                  required
                  value={formData.idade}
                  onChange={handleInputChange}
                />
              </div>
              <div className="presente-form-group">
                <textarea
                  name="mensagem"
                  placeholder="Deixe uma mensagem para mim (opcional)"
                  value={formData.mensagem}
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="submit"
                className="presente-submit-button"
              >
                Receber Meu Presente
              </button>

              {/* NOVO: Notificação dentro do modal */}
              {showNotification && (
                <div className="presente-notification">
                  Obrigado por se inscrever! Você será direcionado para o treinamento.
                </div>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Presente;
