import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView as trackGA } from "./analytics";
import { trackPageView as trackFB } from "./facebookPixel";

function RouteChangeTracker({ children }) {
  const location = useLocation();

  useEffect(() => {
    // Google Analytics
    trackGA(location.pathname + location.search);
    // Facebook Pixel
    trackFB();
  }, [location]);

  return children;
}

export default RouteChangeTracker;
