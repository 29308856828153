// GerenciamentoAnuncios.js
// Este arquivo contém todas as funções para editar, alterar status, alterar orçamento, duplicar itens e agora excluir itens.
// Ele exporta funções que o componente pai (FacebookAdsDashboard) pode chamar passando o token, IDs e parâmetros necessários.

import axios from 'axios';
import { toast } from 'react-toastify';

// Mapeamento de objetivos da campanha para objetivos válidos na API do Facebook
// Ajuste conforme necessário
const objectiveMap = {
  OUTCOME_ENGAGEMENT: 'ENGAGEMENT',
  OUTCOME_SALES: 'CONVERSIONS',
  OUTCOME_LEAD_GENERATION: 'LEAD_GENERATION',
};

// Versão da API do Facebook (Atualizada para v21.0 conforme a documentação)
const FB_API_VERSION = 'v21.0';

// Função auxiliar para enviar requisições POST com dados no corpo em formato URL-encoded
const postRequest = async (url, data) => {
  try {
    const response = await axios.post(url, new URLSearchParams(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      console.error('Erro detalhado da API do Facebook:', error.response.data);
      toast.error(`Erro: ${error.response.data.error.message}`);
    } else {
      console.error('Erro na requisição:', error);
      toast.error('Ocorreu um erro na requisição.');
    }
    throw error;
  }
};

// -------------------- Funções de Edição Simples ---------------------

export async function editarNomeItem(facebookToken, itemId, nivel, novoNome) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const data = {
      access_token: facebookToken,
      name: novoNome,
    };

    await postRequest(endpoint, data);
    toast.success(
      `${
        nivel === 'campaign'
          ? 'Campanha'
          : nivel === 'adset'
          ? 'Conjunto de Anúncio'
          : 'Anúncio'
      } atualizado com sucesso!`
    );
  } catch (error) {
    console.error(`Erro ao editar o nome do ${nivel}:`, error);
  }
}

export async function alterarStatusItem(facebookToken, itemId, nivel, novoStatus) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const data = {
      access_token: facebookToken,
      status: novoStatus,
    };

    await postRequest(endpoint, data);
    toast.success(
      `${
        nivel === 'campaign'
          ? 'Campanha'
          : nivel === 'adset'
          ? 'Conjunto de Anúncio'
          : 'Anúncio'
      } ${
        novoStatus === 'ACTIVE' ? 'ativado' : 'desativado'
      } com sucesso!`
    );
  } catch (error) {
    console.error(`Erro ao alterar status do ${nivel}:`, error);
  }
}

export async function alterarOrcamentoItem(
  facebookToken,
  itemId,
  nivel,
  novoOrcamento,
  isLifetimeBudget = false
) {
  try {
    const endpoint = `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`;
    const budgetValue = Math.round(novoOrcamento * 100).toString(); // Orçamento em centavos como string
    const data = {
      access_token: facebookToken,
    };

    if (isLifetimeBudget) {
      data.lifetime_budget = budgetValue;
    } else {
      data.daily_budget = budgetValue;
    }

    await postRequest(endpoint, data);
    toast.success(
      `${
        nivel === 'campaign'
          ? 'Campanha'
          : nivel === 'adset'
          ? 'Conjunto de Anúncio'
          : 'Anúncio'
      } atualizado com novo orçamento!`
    );
  } catch (error) {
    console.error(`Erro ao alterar orçamento do ${nivel}:`, error);
  }
}

// -------------------- Funções de Duplicação Completa ---------------------

async function getItemData(facebookToken, itemId, fields) {
  try {
    const response = await axios.get(
      `https://graph.facebook.com/${FB_API_VERSION}/${itemId}`,
      {
        params: {
          access_token: facebookToken,
          fields: fields.join(','),
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error(`Erro ao obter dados do item ${itemId}:`, error);
    toast.error('Erro ao obter dados do item.');
    throw error;
  }
}

async function getAdSetsForCampaign(facebookToken, campaignId) {
  try {
    const response = await axios.get(
      `https://graph.facebook.com/${FB_API_VERSION}/${campaignId}/adsets`,
      {
        params: {
          access_token: facebookToken,
          fields:
            'id,name,optimization_goal,destination_type,daily_budget,lifetime_budget,status,bid_strategy,campaign_id,billing_event,promoted_object',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error(`Erro ao obter ad sets da campanha ${campaignId}:`, error);
    toast.error('Erro ao obter conjuntos de anúncios da campanha.');
    throw error;
  }
}

async function getAdsForAdSet(facebookToken, adSetId) {
  try {
    const response = await axios.get(
      `https://graph.facebook.com/${FB_API_VERSION}/${adSetId}/ads`,
      {
        params: {
          access_token: facebookToken,
          fields: 'id,name,status,creative{object_story_spec},adset_id,campaign_id',
        },
      }
    );

    return response.data.data;
  } catch (error) {
    console.error(
      `Erro ao obter anúncios do conjunto de anúncios ${adSetId}:`,
      error
    );
    toast.error('Erro ao obter anúncios do conjunto de anúncios.');
    throw error;
  }
}

async function createCampaign(facebookToken, accountId, originalCampaignData) {
  let objective = originalCampaignData.objective;
  if (objectiveMap[objective]) {
    objective = objectiveMap[objective];
  }

  const data = {
    access_token: facebookToken,
    name: `${originalCampaignData.name} (Cópia)`,
    objective: objective,
    status:
      ['ACTIVE', 'PAUSED'].includes(originalCampaignData.status)
        ? originalCampaignData.status
        : 'PAUSED',
    buying_type: originalCampaignData.buying_type || 'AUCTION',
  };

  if (originalCampaignData.daily_budget) {
    data.daily_budget = originalCampaignData.daily_budget;
  }
  if (originalCampaignData.lifetime_budget) {
    data.lifetime_budget = originalCampaignData.lifetime_budget;
  }

  try {
    const response = await postRequest(
      `https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/campaigns`,
      data
    );
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar campanha duplicada:`, error);
    throw error;
  }
}

async function createAdSet(
  facebookToken,
  accountId,
  newCampaignId,
  originalAdSetData
) {
  const data = {
    access_token: facebookToken,
    name: `${originalAdSetData.name} (Cópia)`,
    campaign_id: newCampaignId,
    billing_event: originalAdSetData.billing_event || 'IMPRESSIONS',
    optimization_goal: originalAdSetData.optimization_goal || 'REACH',
    promoted_object: JSON.stringify(originalAdSetData.promoted_object || {}),
    status:
      ['ACTIVE', 'PAUSED'].includes(originalAdSetData.status)
        ? originalAdSetData.status
        : 'PAUSED',
  };

  if (originalAdSetData.daily_budget) {
    data.daily_budget = originalAdSetData.daily_budget;
  } else if (originalAdSetData.lifetime_budget) {
    data.lifetime_budget = originalAdSetData.lifetime_budget;
  } else {
    data.daily_budget = '1000'; // Orçamento mínimo
  }

  try {
    const response = await postRequest(
      `https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/adsets`,
      data
    );
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar conjunto de anúncios duplicado:`, error);
    throw error;
  }
}

async function createAdCreative(
  facebookToken,
  accountId,
  originalCreativeObject
) {
  const data = {
    access_token: facebookToken,
    name: `${originalCreativeObject.title || 'Criativo'} (Cópia)`,
    object_story_spec: JSON.stringify(originalCreativeObject),
  };

  try {
    const response = await postRequest(
      `https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/adcreatives`,
      data
    );
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar criativo duplicado:`, error);
    throw error;
  }
}

async function createAd(
  facebookToken,
  accountId,
  newAdSetId,
  newCreativeId,
  originalAdData
) {
  const data = {
    access_token: facebookToken,
    name: `${originalAdData.name} (Cópia)`,
    adset_id: newAdSetId,
    status:
      ['ACTIVE', 'PAUSED'].includes(originalAdData.status)
        ? originalAdData.status
        : 'PAUSED',
    creative: `{"creative_id":"${newCreativeId}"}`,
  };

  try {
    const response = await postRequest(
      `https://graph.facebook.com/${FB_API_VERSION}/act_${accountId}/ads`,
      data
    );
    return response.id;
  } catch (error) {
    console.error(`Erro ao criar anúncio duplicado:`, error);
    throw error;
  }
}

async function duplicarCampanhaCompleta(facebookToken, accountId, campaignId) {
  try {
    const campaignFields = [
      'name',
      'objective',
      'status',
      'buying_type',
      'daily_budget',
      'lifetime_budget',
    ];
    const originalCampaignData = await getItemData(
      facebookToken,
      campaignId,
      campaignFields
    );

    const newCampaignId = await createCampaign(
      facebookToken,
      accountId,
      originalCampaignData
    );

    const originalAdSets = await getAdSetsForCampaign(facebookToken, campaignId);

    for (const originalAdSet of originalAdSets) {
      const newAdSetId = await createAdSet(
        facebookToken,
        accountId,
        newCampaignId,
        originalAdSet
      );

      const originalAds = await getAdsForAdSet(facebookToken, originalAdSet.id);

      for (const originalAd of originalAds) {
        const originalCreative = originalAd.creative?.object_story_spec;
        if (!originalCreative) {
          continue;
        }
        const newCreativeId = await createAdCreative(
          facebookToken,
          accountId,
          originalCreative
        );
        await createAd(
          facebookToken,
          accountId,
          newAdSetId,
          newCreativeId,
          originalAd
        );
      }
    }

    toast.success(
      'Campanha e todos os seus conjuntos e anúncios duplicados com sucesso!'
    );
  } catch (error) {
    console.error('Erro ao duplicar campanha completa:', error);
  }
}

async function duplicarAdSetCompleto(facebookToken, accountId, adSetId) {
  try {
    const adSetFields = [
      'name',
      'optimization_goal',
      'destination_type',
      'daily_budget',
      'lifetime_budget',
      'status',
      'billing_event',
      'campaign_id',
      'promoted_object',
    ];
    const originalAdSetData = await getItemData(
      facebookToken,
      adSetId,
      adSetFields
    );

    const newAdSetId = await createAdSet(
      facebookToken,
      accountId,
      originalAdSetData.campaign_id,
      originalAdSetData
    );

    const originalAds = await getAdsForAdSet(facebookToken, adSetId);

    for (const originalAd of originalAds) {
      const originalCreative = originalAd.creative?.object_story_spec;
      if (!originalCreative) continue;
      const newCreativeId = await createAdCreative(
        facebookToken,
        accountId,
        originalCreative
      );
      await createAd(
        facebookToken,
        accountId,
        newAdSetId,
        newCreativeId,
        originalAd
      );
    }

    toast.success(
      'Conjunto de anúncios e todos seus anúncios duplicados com sucesso!'
    );
  } catch (error) {
    console.error('Erro ao duplicar conjunto de anúncios completo:', error);
  }
}

async function duplicarAnuncioCompleto(facebookToken, accountId, adId) {
  try {
    const adFields = ['name', 'status', 'adset_id', 'creative{object_story_spec}'];
    const originalAdData = await getItemData(facebookToken, adId, adFields);
    const originalCreative = originalAdData.creative?.object_story_spec;
    if (!originalCreative) {
      toast.error('Não foi possível duplicar anúncio: não há criativo definido.');
      return;
    }

    const newCreativeId = await createAdCreative(
      facebookToken,
      accountId,
      originalCreative
    );

    await createAd(
      facebookToken,
      accountId,
      originalAdData.adset_id,
      newCreativeId,
      originalAdData
    );

    toast.success('Anúncio duplicado com sucesso!');
  } catch (error) {
    console.error('Erro ao duplicar anúncio completo:', error);
  }
}

export async function duplicarSelecaoCompleta(
  facebookToken,
  selectedItems,
  currentLevel,
  selectedAccount
) {
  // Removendo "act_" caso exista
  const accountId = selectedAccount.replace('act_', '');

  try {
    if (currentLevel === 'campaigns' && selectedItems.campaigns.length > 0) {
      for (const campaignId of selectedItems.campaigns) {
        await duplicarCampanhaCompleta(facebookToken, accountId, campaignId);
      }
    } else if (currentLevel === 'adSets' && selectedItems.adSets.length > 0) {
      for (const adSetId of selectedItems.adSets) {
        await duplicarAdSetCompleto(facebookToken, accountId, adSetId);
      }
    } else if (currentLevel === 'ads' && selectedItems.ads.length > 0) {
      for (const adId of selectedItems.ads) {
        await duplicarAnuncioCompleto(facebookToken, accountId, adId);
      }
    } else {
      toast.info('Nenhum item selecionado para duplicar.');
    }
  } catch (error) {
    console.error('Erro na duplicação da seleção completa:', error);
  }
}

// -------------------- Funções de Exclusão ---------------------

// Exclusão de Campanha
async function excluirCampanha(facebookToken, campaignId) {
  try {
    const response = await axios.delete(
      `https://graph.facebook.com/${FB_API_VERSION}/${campaignId}`,
      {
        params: {
          access_token: facebookToken,
        },
      }
    );
    if (response.data.success) {
      toast.success('Campanha excluída com sucesso!');
    }
  } catch (error) {
    console.error('Erro ao excluir campanha:', error);
    toast.error('Erro ao excluir campanha.');
  }
}

// Exclusão de Conjunto de Anúncios
async function excluirAdSet(facebookToken, adSetId) {
  try {
    const response = await axios.delete(
      `https://graph.facebook.com/${FB_API_VERSION}/${adSetId}`,
      {
        params: {
          access_token: facebookToken,
        },
      }
    );
    if (response.data.success) {
      toast.success('Conjunto de anúncios excluído com sucesso!');
    }
  } catch (error) {
    console.error('Erro ao excluir conjunto de anúncios:', error);
    toast.error('Erro ao excluir conjunto de anúncios.');
  }
}

// Exclusão de Anúncio
async function excluirAnuncio(facebookToken, adId) {
  try {
    const response = await axios.delete(
      `https://graph.facebook.com/${FB_API_VERSION}/${adId}`,
      {
        params: {
          access_token: facebookToken,
        },
      }
    );
    if (response.data.success) {
      toast.success('Anúncio excluído com sucesso!');
    }
  } catch (error) {
    console.error('Erro ao excluir anúncio:', error);
    toast.error('Erro ao excluir anúncio.');
  }
}

// Função principal para exclusão completa
export async function excluirSelecaoCompleta(
  facebookToken,
  selectedItems,
  currentLevel,
  selectedAccount
) {
  try {
    if (currentLevel === 'campaigns' && selectedItems.campaigns.length > 0) {
      for (const campaignId of selectedItems.campaigns) {
        await excluirCampanha(facebookToken, campaignId);
      }
    } else if (currentLevel === 'adSets' && selectedItems.adSets.length > 0) {
      for (const adSetId of selectedItems.adSets) {
        await excluirAdSet(facebookToken, adSetId);
      }
    } else if (currentLevel === 'ads' && selectedItems.ads.length > 0) {
      for (const adId of selectedItems.ads) {
        await excluirAnuncio(facebookToken, adId);
      }
    } else {
      toast.info('Nenhum item selecionado para excluir.');
    }
  } catch (error) {
    console.error('Erro na exclusão da seleção completa:', error);
    toast.error('Erro ao excluir seleção completa.');
  }
}
