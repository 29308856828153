import React, { useEffect, useState } from 'react';
import logo from '../../imagens/logo_menu_login.webp';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated, logout, getUserName } from '../../auth/auth';
import './AnaliseMetricas.css';
import {
  FaArrowLeft,
  FaBullhorn,
  FaCopy,
  FaSyncAlt,
  FaCalendarAlt,
  FaChartLine,
  FaFilter,
  FaTrash,
  FaMoneyBillWave,
  FaToggleOn,
} from 'react-icons/fa';
import FacebookLogin from './FacebookLogin';
import axios from 'axios';
import qs from 'qs';
import { motion } from 'framer-motion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCachedData, setCachedData } from './cache';
import {
  metricNames,
  defaultMetrics,
  sumMetrics,
  averageMetrics,
  metricOrder,
} from './MetricsConfig';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import Chat from './Chat';
import Metricaexplicada from './Metricaexplicada.js';
import {
  editarNomeItem,
  alterarStatusItem,
  alterarOrcamentoItem,
  duplicarSelecaoCompleta,
  excluirSelecaoCompleta,
} from './GerenciamentoAnuncios.js';

// Definições de campaignObjectives e destinationTypes
const campaignObjectives = {
  OUTCOME_ENGAGEMENT: 'Engajamento',
  OUTCOME_SALES: 'Vendas',
  OUTCOME_LEAD_GENERATION: 'Geração de Leads',
};

const destinationTypes = {
  WHATSAPP: 'WhatsApp',
  WEBSITE: 'Site',
  MOBILE_APP: 'Aplicativo Móvel',
};

// Componente para Seleção de Cores das Métricas
const MetricColorSelector = ({
  metric,
  selectedMetricsColors,
  setSelectedMetricsColors,
}) => {
  const colors = ['#22c55e', '#3b82f6', '#ef4444'];

  const handleColorClick = (color) => {
    const currentColor = selectedMetricsColors[metric];
    let newColors = { ...selectedMetricsColors };

    if (currentColor === color) {
      delete newColors[metric];
    } else {
      newColors[metric] = color;
    }

    setSelectedMetricsColors(newColors);
    localStorage.setItem('selectedMetricsColors', JSON.stringify(newColors));
  };

  return (
    <div className="metric-color-selector">
      {colors.map((color) => (
        <span
          key={color}
          className={`color-circle ${
            selectedMetricsColors[metric] === color ? 'selected-color' : ''
          }`}
          style={{ backgroundColor: color }}
          onClick={() => handleColorClick(color)}
          title={`Selecionar ${metricNames[metric]}`}
        />
      ))}
    </div>
  );
};

const FacebookAdsDashboard = () => {
  const navigate = useNavigate();
  const userEmail = localStorage.getItem('userEmail') || '';

  const [facebookToken, setFacebookToken] = useState(
    localStorage.getItem(`facebookToken_${userEmail}`) ||
      sessionStorage.getItem(`facebookToken_${userEmail}`) ||
      null
  );

  const [accounts, setAccounts] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [adSets, setAdSets] = useState([]);
  const [ads, setAds] = useState([]);
  const [metrics, setMetrics] = useState({});
  const [totalMetrics, setTotalMetrics] = useState({});
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedItems, setSelectedItems] = useState({
    campaigns: [],
    adSets: [],
    ads: [],
  });
  const [currentLevel, setCurrentLevel] = useState('campaigns');
  const [dateRange, setDateRange] = useState({ since: '', until: '' });
  const [datePreset, setDatePreset] = useState('');
  const [loading, setLoading] = useState(false);
  const [metricsFetched, setMetricsFetched] = useState(false);
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [showMetricsSelector, setShowMetricsSelector] = useState(false);
  const [showDateSelector, setShowDateSelector] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [statusFilter, setStatusFilter] = useState('Qualquer');
  const [nameFilter, setNameFilter] = useState('');
  const [columnWidths, setColumnWidths] = useState({});
  const [isResizing, setIsResizing] = useState(false);
  const [currentMetric, setCurrentMetric] = useState(null);

  const [selectedMetricsColors, setSelectedMetricsColors] = useState(() => {
    const savedColors = localStorage.getItem('selectedMetricsColors');
    return savedColors ? JSON.parse(savedColors) : {};
  });

  const [metricsForChat, setMetricsForChat] = useState(null);

  // Estado para popup de duplicação
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false);
  const [duplicateCopiesCount, setDuplicateCopiesCount] = useState(1);

  // Estado para popup de excluir
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  // Estado para pop-up de editar nome
  const [showEditNamePopup, setShowEditNamePopup] = useState(false);
  const [editNameItemId, setEditNameItemId] = useState(null);
  const [editNameValue, setEditNameValue] = useState('');

  // Estado para pop-up de editar orçamento
  const [showEditBudgetPopup, setShowEditBudgetPopup] = useState(false);
  const [editBudgetItemId, setEditBudgetItemId] = useState(null);
  const [editBudgetValue, setEditBudgetValue] = useState('');
  const [isLifetimeBudget, setIsLifetimeBudget] = useState(false);

  // Estado para filtrar somente selecionadas
  const [filterOnlySelected, setFilterOnlySelected] = useState(false);

  // Estado para pop-up de alterar orçamento em massa
  const [showBulkBudgetPopup, setShowBulkBudgetPopup] = useState(false);
  const [bulkBudgetValue, setBulkBudgetValue] = useState('');
  const [isLifetimeBulk, setIsLifetimeBulk] = useState(false);

  // Ao montar, carrega as métricas do localStorage (para não sumirem ao recarregar)
  useEffect(() => {
    const savedMetrics = localStorage.getItem(`metrics_${userEmail}`);
    if (savedMetrics) {
      setMetrics(JSON.parse(savedMetrics));
    }
    const savedTotalMetrics = localStorage.getItem(`totalMetrics_${userEmail}`);
    if (savedTotalMetrics) {
      setTotalMetrics(JSON.parse(savedTotalMetrics));
    }
  }, [userEmail]);

  // Sempre que metrics ou totalMetrics mudarem, salvamos em localStorage
  useEffect(() => {
    localStorage.setItem(`metrics_${userEmail}`, JSON.stringify(metrics));
  }, [metrics, userEmail]);

  useEffect(() => {
    localStorage.setItem(`totalMetrics_${userEmail}`, JSON.stringify(totalMetrics));
  }, [totalMetrics, userEmail]);

  useEffect(() => {
    const savedSelectedMetrics = localStorage.getItem(
      `selectedMetrics_${userEmail}`
    );
    if (savedSelectedMetrics) {
      setSelectedMetrics(JSON.parse(savedSelectedMetrics));
    } else {
      setSelectedMetrics(defaultMetrics);
      localStorage.setItem(
        `selectedMetrics_${userEmail}`,
        JSON.stringify(defaultMetrics)
      );
    }
  }, [userEmail]);

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(true);
      setLoading(false);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 401 || (data && data.error && data.error.code === 190)) {
            toast.error('Sessão expirada. Por favor, faça login novamente.', {
              position: 'top-right',
              autoClose: 5000,
            });
            handleLogout();
          } else if (status === 400) {
            toast.error('Por favor, aguarde um momento e atualize a página.', {
              position: 'top-right',
              autoClose: 5000,
            });
          } else if (status === 429) {
            toast.error('Limite de requisições excedido. Tente novamente mais tarde.', {
              position: 'top-right',
              autoClose: 5000,
            });
          } else {
            toast.error('Ocorreu um erro inesperado.', {
              position: 'top-right',
              autoClose: 5000,
            });
          }
        } else if (error.request) {
          toast.error('Erro na conexão com o servidor.', {
            position: 'top-right',
            autoClose: 5000,
          });
        } else {
          toast.error('Ocorreu um erro: ' + error.message, {
            position: 'top-right',
            autoClose: 5000,
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);

  useEffect(() => {
    const savedDateRange = localStorage.getItem(`dateRange_${userEmail}`);
    const savedDatePreset = localStorage.getItem(`datePreset_${userEmail}`);
    if (savedDateRange) {
      setDateRange(JSON.parse(savedDateRange));
    } else {
      setDateRange({ since: '', until: '' });
    }
    if (savedDatePreset) {
      setDatePreset(savedDatePreset);
    }

    const savedAccount = localStorage.getItem('selectedAccount');
    if (savedAccount) {
      setSelectedAccount(savedAccount);
    }

    const intervalId = setInterval(() => {
      const token =
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (token !== facebookToken) {
        setFacebookToken(token);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [userEmail, facebookToken]);

  useEffect(() => {
    if (facebookToken) {
      fetchAdAccounts(facebookToken);
      setError(null);
    } else {
      setError(null);
      setAccounts([]);
      toast.info('Por favor, faça login no Facebook para acessar as métricas.', {
        position: 'top-right',
        autoClose: 5000,
      });
    }
  }, [facebookToken, userEmail]);

  const fetchAdAccounts = async (token = null) => {
    setLoading(true);
    const loadingToastId = toast.loading('Carregando contas de anúncio...');
    try {
      const accessToken =
        token ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setAccounts([]);
        toast.update(loadingToastId, {
          render: 'Token de acesso não encontrado.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      const cachedAccounts = getCachedData(`adAccounts_${userEmail}`);
      if (cachedAccounts) {
        setAccounts(cachedAccounts);
        toast.update(loadingToastId, {
          render: 'Contas de anúncio carregadas do cache.',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        setLoading(false);
        return;
      }

      const response = await axios.get(
        'https://graph.facebook.com/v21.0/me/adaccounts',
        {
          params: {
            access_token: accessToken,
            fields: 'id,name',
          },
        }
      );
      setAccounts(response.data.data);
      setCachedData(`adAccounts_${userEmail}`, response.data.data);

      const savedAccount = localStorage.getItem('selectedAccount');
      if (
        savedAccount &&
        response.data.data.some((acc) => acc.id === savedAccount)
      ) {
        setSelectedAccount(savedAccount);
        await fetchCampaigns(savedAccount);
      } else if (response.data.data.length > 0) {
        const firstAccount = response.data.data[0];
        setSelectedAccount(firstAccount.id);
        localStorage.setItem('selectedAccount', firstAccount.id);
        await fetchCampaigns(firstAccount.id);
      }

      toast.update(loadingToastId, {
        render: 'Contas de anúncio carregadas com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Erro ao buscar contas de anúncio:', error);
      toast.update(loadingToastId, {
        render: 'Erro ao carregar contas de anúncio.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const getBudgetValue = (item) => {
    if (item.lifetime_budget && item.lifetime_budget !== '0') {
      return parseFloat(item.lifetime_budget) / 100;
    } else if (item.daily_budget && item.daily_budget !== '0') {
      return parseFloat(item.daily_budget) / 100;
    } else if (item.budget_remaining && item.budget_remaining !== '0') {
      return parseFloat(item.budget_remaining) / 100;
    } else {
      return 0;
    }
  };

  const fetchCampaigns = async (accountId) => {
    setSelectedAccount(accountId);
    localStorage.setItem('selectedAccount', accountId);
    setCampaigns([]);
    setAdSets([]);
    setAds([]);
    setMetrics({});
    setTotalMetrics({});
    setCurrentLevel('campaigns');
    setSelectedItems({ campaigns: [], adSets: [], ads: [] });
    setLoading(true);
    const loadingToastId = toast.loading('Carregando campanhas...');
    try {
      const cachedCampaigns = getCachedData(
        `campaigns_${accountId}_${userEmail}`
      );
      if (cachedCampaigns) {
        setCampaigns(cachedCampaigns);
        toast.update(loadingToastId, {
          render: 'Campanhas carregadas do cache.',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        if (dateRange.since && dateRange.until) {
          await fetchMetrics(cachedCampaigns, 'campaigns');
        }
        return;
      }

      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      const response = await axios.get(
        `https://graph.facebook.com/v21.0/${accountId}/campaigns`,
        {
          params: {
            access_token: accessToken,
            fields:
              'id,name,objective,buying_type,is_campaign_budget_optimization,status,daily_budget,lifetime_budget,budget_remaining',
            limit: 500,
          },
        }
      );
      setCampaigns(response.data.data);
      setCachedData(
        `campaigns_${accountId}_${userEmail}`,
        response.data.data
      );

      if (dateRange.since && dateRange.until) {
        await fetchMetrics(response.data.data, 'campaigns');
      }

      toast.update(loadingToastId, {
        render: 'Campanhas carregadas com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Erro ao buscar campanhas:', error);
      toast.update(loadingToastId, {
        render: 'Erro ao carregar campanhas.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAdSets = async (campaignIds) => {
    setAdSets([]);
    setAds([]);
    setMetrics({});
    setTotalMetrics({});
    setLoading(true);
    const loadingToastId = toast.loading('Carregando conjuntos de anúncios...');
    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, {
          render: 'Token de acesso não encontrado.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      let allAdSets = [];
      let ids = Array.isArray(campaignIds) ? campaignIds : [campaignIds];

      if (ids.length === 0) {
        ids = campaigns.map((campaign) => campaign.id);
      }

      const adSetPromises = ids.map(async (campaignId) => {
        const cachedAdSets = getCachedData(
          `adSets_${campaignId}_${userEmail}`
        );
        if (cachedAdSets) {
          return cachedAdSets;
        } else {
          const maxRetries = 5;
          let attempt = 0;
          let response;
          while (attempt < maxRetries) {
            try {
              response = await axios.get(
                `https://graph.facebook.com/v21.0/${campaignId}/adsets`,
                {
                  params: {
                    access_token: accessToken,
                    fields:
                      'id,name,optimization_goal,promoted_object,destination_type,daily_budget,lifetime_budget,budget_remaining,status,campaign_id,is_dynamic_creative',
                    limit: 500,
                  },
                }
              );
              break;
            } catch (err) {
              attempt += 1;
              console.error(
                `Erro na tentativa ${attempt} para campanha ID: ${campaignId}:`,
                err.response?.data || err.message
              );
              if (attempt >= maxRetries) {
                throw err;
              }
              await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
            }
          }
          setCachedData(
            `adSets_${campaignId}_${userEmail}`,
            response.data.data
          );
          return response.data.data;
        }
      });

      const results = await Promise.allSettled(adSetPromises);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          allAdSets = allAdSets.concat(result.value);
        } else {
          console.error(
            `Erro ao buscar conjuntos de anúncios para a campanha ID ${ids[index]}:`,
            result.reason
          );
          toast.error(
            `Erro ao carregar conjuntos de anúncios para a campanha ID ${ids[index]}.`,
            {
              position: 'top-right',
              autoClose: 5000,
            }
          );
        }
      });

      setAdSets(allAdSets);

      if (dateRange.since && dateRange.until) {
        await fetchMetrics(allAdSets, 'adSets');
      }

      toast.update(loadingToastId, {
        render: 'Conjuntos de anúncios carregados com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Erro ao buscar conjuntos de anúncios:', error);
      toast.update(loadingToastId, {
        render: 'Erro ao carregar conjuntos de anúncios.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchAds = async (adSetIds) => {
    setAds([]);
    setMetrics({});
    setTotalMetrics({});
    setLoading(true);
    const loadingToastId = toast.loading('Carregando anúncios...');
    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, {
          render: 'Token de acesso não encontrado.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      let allAds = [];
      let ids = Array.isArray(adSetIds) ? adSetIds : [adSetIds];

      if (ids.length === 0) {
        ids = adSets.map((adSet) => adSet.id);
      }

      const adPromises = ids.map(async (adSetId) => {
        const cachedAds = getCachedData(`ads_${adSetId}_${userEmail}`);
        if (cachedAds) {
          return cachedAds;
        } else {
          const maxRetries = 5;
          let attempt = 0;
          let response;
          while (attempt < maxRetries) {
            try {
              response = await axios.get(
                `https://graph.facebook.com/v21.0/${adSetId}/ads`,
                {
                  params: {
                    access_token: accessToken,
                    fields:
                      'id,name,creative{object_story_spec},status,adset_id,campaign_id',
                    limit: 500,
                  },
                }
              );
              break;
            } catch (err) {
              attempt += 1;
              console.error(
                `Erro na tentativa ${attempt} para AdSet ID: ${adSetId}:`,
                err.response?.data || err.message
              );
              if (attempt >= maxRetries) {
                throw err;
              }
              await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
            }
          }
          const adsWithCreativeType = response.data.data.map((ad) => ({
            ...ad,
            creative_type: getCreativeType(ad.creative?.object_story_spec),
            adset_id: ad.adset_id,
          }));
          setCachedData(`ads_${adSetId}_${userEmail}`, adsWithCreativeType);
          return adsWithCreativeType;
        }
      });

      const results = await Promise.allSettled(adPromises);
      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          allAds = allAds.concat(result.value);
        } else {
          console.error(
            `Erro ao buscar anúncios para o AdSet ID ${ids[index]}:`,
            result.reason
          );
          toast.error(
            `Erro ao carregar anúncios para o AdSet ID ${ids[index]}.`,
            {
              position: 'top-right',
              autoClose: 5000,
            }
          );
        }
      });

      setAds(allAds);

      if (dateRange.since && dateRange.until) {
        await fetchMetrics(allAds, 'ads');
      }

      toast.update(loadingToastId, {
        render: 'Anúncios carregados com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Erro ao buscar anúncios:', error);
      toast.update(loadingToastId, {
        render: 'Erro ao carregar anúncios.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchMetrics = async (items, level) => {
    setMetrics({});
    setMetricsFetched(false);
    setLoading(true);
    setTotalMetrics({});
    const loadingToastId = toast.loading('Carregando métricas...');

    if (!dateRange.since || !dateRange.until) {
      toast.update(loadingToastId, {
        render: 'Por favor, selecione uma data inicial e final.',
        type: 'warning',
        isLoading: false,
        autoClose: 5000,
      });
      setLoading(false);
      return;
    }

    try {
      const accessToken =
        facebookToken ||
        localStorage.getItem(`facebookToken_${userEmail}`) ||
        sessionStorage.getItem(`facebookToken_${userEmail}`);
      if (!accessToken) {
        setError('Token de acesso não encontrado.');
        toast.update(loadingToastId, {
          render: 'Token de acesso não encontrado.',
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
        return;
      }

      const levelMapping = {
        campaigns: 'campaign',
        adSets: 'adset',
        ads: 'ad',
      };

      let totals = {};
      let counts = {};
      let cpmNumerator = 0;
      let cpmDenominator = 0;
      let cpcNumerator = 0;
      let cpcDenominator = 0;
      let ctrNumerator = 0;
      let ctrDenominator = 0;
      let totalVideo3SecViews = 0;
      let totalVideo75Views = 0;
      let totalImpressions = 0;
      let totalInlineLinkClicks = 0;

      totals.impressions = 0;
      totals.reach = 0;
      totals.budget = 0;

      selectedMetrics.forEach((metric) => {
        totals[metric] = 0;
        counts[metric] = 0;
      });

      const metricToFieldMap = {
        impressions: 'impressions',
        reach: 'reach',
        spend: 'spend',
        cpm: 'cpm',
        link_click_ctr: 'inline_link_click_ctr',
        link_click_cpc: 'cost_per_inline_link_click',
        frequency: 'frequency',
        inline_link_clicks: 'inline_link_clicks',
        totalResults: 'actions',
        costPerResult: 'cost_per_action_type',
        video3SecViews: 'actions',
        video75Views: 'video_p75_watched_actions',
      };

      const requiredFields = new Set();

      selectedMetrics.forEach((metric) => {
        if (metricToFieldMap[metric]) {
          requiredFields.add(metricToFieldMap[metric]);
        }
      });

      if (
        selectedMetrics.includes('hookRate') ||
        selectedMetrics.includes('holdRate')
      ) {
        requiredFields.add('impressions');
        requiredFields.add('actions');
        requiredFields.add('video_p75_watched_actions');
      }

      const fields = Array.from(requiredFields);

      const fetchPromises = items.map(async (item) => {
        const params = {
          access_token: accessToken,
          fields: fields.join(','),
          level: levelMapping[level],
          limit: 500,
          time_range: {
            since: dateRange.since,
            until: dateRange.until,
          },
        };

        try {
          const response = await axios.get(
            `https://graph.facebook.com/v21.0/${item.id}/insights`,
            {
              params: params,
              paramsSerializer: (params) =>
                qs.stringify(params, { arrayFormat: 'comma' }),
            }
          );

          const data = response.data.data;

          let itemMetrics = {};
          itemMetrics.budget = getBudgetValue(item);
          totals.budget += itemMetrics.budget;

          if (data && data.length > 0) {
            const itemData = data[0];

            selectedMetrics.forEach((metric) => {
              const field = metricToFieldMap[metric];
              let value = itemData[field];

              if (field === 'actions' && Array.isArray(value)) {
                if (metric === 'totalResults') {
                  const action = value.find(
                    (action) =>
                      action.action_type ===
                      'onsite_conversion.messaging_conversation_started_7d'
                  );
                  itemMetrics[metric] = action
                    ? parseFloat(action.value)
                    : 0;
                } else if (metric === 'video3SecViews') {
                  const videoAction = value.find(
                    (action) => action.action_type === 'video_view'
                  );
                  itemMetrics[metric] = videoAction
                    ? parseInt(videoAction.value, 10)
                    : 0;
                }
              } else if (
                field === 'cost_per_action_type' &&
                Array.isArray(value)
              ) {
                const costAction = value.find(
                  (action) =>
                    action.action_type ===
                    'onsite_conversion.messaging_conversation_started_7d'
                );
                itemMetrics[metric] = costAction
                  ? parseFloat(costAction.value)
                  : 0;
              } else if (field === 'video_p75_watched_actions') {
                if (Array.isArray(value) && value.length > 0) {
                  const video75Action = value[0];
                  itemMetrics[metric] = video75Action
                    ? parseInt(video75Action.value, 10)
                    : 0;
                } else {
                  itemMetrics[metric] = 0;
                }
              } else if (typeof value === 'string' || typeof value === 'number') {
                itemMetrics[metric] = parseFloat(value);
              } else {
                itemMetrics[metric] = 0;
              }
            });

            if (selectedMetrics.includes('hookRate')) {
              const impressions = parseInt(itemData.impressions, 10) || 0;
              const video3SecViews = itemMetrics.video3SecViews || 0;
              itemMetrics.hookRate =
                impressions > 0 ? (video3SecViews / impressions) * 100 : 0;
            }

            if (selectedMetrics.includes('holdRate')) {
              const video3SecViews = itemMetrics.video3SecViews || 0;
              const video75Views = itemMetrics.video75Views || 0;
              itemMetrics.holdRate =
                video3SecViews > 0 ? (video75Views / video3SecViews) * 100 : 0;
            }

            setMetrics((prevMetrics) => ({
              ...prevMetrics,
              [item.id]: itemMetrics,
            }));

            selectedMetrics.forEach((metric) => {
              const value = itemMetrics[metric];
              if (!isNaN(value)) {
                if (metric === 'cpm') {
                  const impressions =
                    parseInt(itemData.impressions, 10) || 0;
                  cpmNumerator += value * impressions;
                  cpmDenominator += impressions;
                } else if (metric === 'link_click_cpc') {
                  const clicks =
                    parseInt(itemData.inline_link_clicks, 10) || 0;
                  cpcNumerator += value * clicks;
                  cpcDenominator += clicks;
                } else if (metric === 'link_click_ctr') {
                  const impressions =
                    parseInt(itemData.impressions, 10) || 0;
                  ctrNumerator += value * impressions;
                  ctrDenominator += impressions;
                } else if (metric === 'hookRate') {
                  const impressions =
                    parseInt(itemData.impressions, 10) || 0;
                  const video3SecViews = itemMetrics.video3SecViews || 0;
                  totalVideo3SecViews += video3SecViews;
                  totalImpressions += impressions;
                } else if (metric === 'holdRate') {
                  const video3SecViews = itemMetrics.video3SecViews || 0;
                  const video75Views = itemMetrics.video75Views || 0;
                  totalVideo3SecViews += video3SecViews;
                  totalVideo75Views += video75Views;
                } else if (sumMetrics.includes(metric)) {
                  totals[metric] += value;
                } else if (averageMetrics.includes(metric)) {
                  totals[metric] += value;
                  counts[metric] += 1;
                }
              }
            });

            totals.impressions += parseInt(itemData.impressions, 10) || 0;
            totals.reach += parseInt(itemData.reach, 10) || 0;
            totalImpressions += parseInt(itemData.impressions, 10) || 0;
            totalInlineLinkClicks +=
              parseInt(itemData.inline_link_clicks, 10) || 0;
          } else {
            selectedMetrics.forEach((metric) => {
              itemMetrics[metric] = 0;
            });
            itemMetrics.budget = getBudgetValue(item);
          }
        } catch (error) {
          console.error(`Erro ao buscar métricas para ${item.id}:`, error);
          toast.error(`Erro ao carregar métricas para ${item.name}.`, {
            position: 'top-right',
            autoClose: 5000,
          });
        }
      });

      await Promise.allSettled(fetchPromises);

      if (totals.reach > 0) {
        totals.frequency = totals.impressions / totals.reach;
      } else {
        totals.frequency = 0;
      }

      averageMetrics.forEach((metric) => {
        if (counts[metric] > 0) {
          totals[metric] = totals[metric] / counts[metric];
        } else {
          totals[metric] = 0;
        }
      });

      if (selectedMetrics.includes('cpm')) {
        totals.cpm = cpmDenominator > 0 ? cpmNumerator / cpmDenominator : 0;
      }

      if (selectedMetrics.includes('link_click_cpc')) {
        totals.link_click_cpc =
          cpcDenominator > 0 ? cpcNumerator / cpcDenominator : 0;
      }

      if (selectedMetrics.includes('link_click_ctr')) {
        totals.link_click_ctr =
          ctrDenominator > 0 ? ctrNumerator / ctrDenominator : 0;
      }

      if (selectedMetrics.includes('hookRate')) {
        totals.hookRate =
          totalImpressions > 0
            ? (totalVideo3SecViews / totalImpressions) * 100
            : 0;
      }

      if (selectedMetrics.includes('holdRate')) {
        totals.holdRate =
          totalVideo3SecViews > 0
            ? (totalVideo75Views / totalVideo3SecViews) * 100
            : 0;
      }

      setTotalMetrics(totals);
      setMetricsFetched(true);
      setLastUpdateTime(new Date());

      toast.update(loadingToastId, {
        render: 'Métricas carregadas com sucesso.',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error('Erro ao buscar métricas:', error.response?.data || error);
      toast.update(loadingToastId, {
        render: 'Erro ao carregar métricas.',
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      setMetricsFetched(true);
    } finally {
      setLoading(false);
    }
  };

  const getCreativeType = (objectStorySpec) => {
    if (!objectStorySpec) return 'Não especificado';
    if (objectStorySpec.video_data) return 'Vídeo';
    if (objectStorySpec.template_data?.multi_share_end_card) return 'Carrossel';
    if (objectStorySpec.link_data) return 'Imagem';
    return 'Não especificado';
  };

  // Filtro dos dados
  const getFilteredData = () => {
    let data = [];
    if (currentLevel === 'campaigns') {
      data = campaigns;
    } else if (currentLevel === 'adSets') {
      data = adSets;
    } else if (currentLevel === 'ads') {
      data = ads;
    }

    if (filterOnlySelected) {
      if (currentLevel === 'campaigns') {
        data = data.filter((item) => selectedItems.campaigns.includes(item.id));
      } else if (currentLevel === 'adSets') {
        data = data.filter((item) => selectedItems.adSets.includes(item.id));
      } else if (currentLevel === 'ads') {
        data = data.filter((item) => selectedItems.ads.includes(item.id));
      }
    }

    if (statusFilter !== 'Qualquer') {
      data = data.filter((item) =>
        statusFilter === 'Ativa'
          ? item.status === 'ACTIVE'
          : item.status !== 'ACTIVE'
      );
    }

    if (nameFilter) {
      data = data.filter(
        (item) => item.name && item.name.toLowerCase().includes(nameFilter)
      );
    }

    return data;
  };

  const handleItemClick = async (item) => {
    if (currentLevel === 'campaigns') {
      setSelectedItems({ campaigns: [item.id], adSets: [], ads: [] });
      await fetchAdSets([item.id]);
      setCurrentLevel('adSets');
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: [item.id],
        ads: [],
      }));
      await fetchAds([item.id]);
      setCurrentLevel('ads');
    }
  };

  const handleCheckboxChange = (e, id) => {
    const isChecked = e.target.checked;
    if (currentLevel === 'campaigns') {
      setSelectedItems((prev) => ({
        ...prev,
        campaigns: isChecked
          ? [...prev.campaigns, id]
          : prev.campaigns.filter((itemId) => itemId !== id),
      }));
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: isChecked
          ? [...prev.adSets, id]
          : prev.adSets.filter((itemId) => itemId !== id),
      }));
    } else if (currentLevel === 'ads') {
      setSelectedItems((prev) => ({
        ...prev,
        ads: isChecked
          ? [...prev.ads, id]
          : prev.ads.filter((itemId) => itemId !== id),
      }));
    }
  };

  const isItemSelected = (id) => {
    if (currentLevel === 'campaigns') {
      return selectedItems.campaigns.includes(id);
    } else if (currentLevel === 'adSets') {
      return selectedItems.adSets.includes(id);
    } else if (currentLevel === 'ads') {
      return selectedItems.ads.includes(id);
    }
    return false;
  };

  const areAllItemsSelected = () => {
    const data = getFilteredData();
    if (data.length === 0) return false;
    if (currentLevel === 'campaigns') {
      return data.every((item) => selectedItems.campaigns.includes(item.id));
    } else if (currentLevel === 'adSets') {
      return data.every((item) => selectedItems.adSets.includes(item.id));
    } else if (currentLevel === 'ads') {
      return data.every((item) => selectedItems.ads.includes(item.id));
    }
    return false;
  };

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const data = getFilteredData();
    const ids = data.map((item) => item.id).filter((id) => id);

    if (currentLevel === 'campaigns') {
      setSelectedItems((prev) => ({
        ...prev,
        campaigns: isChecked ? ids : [],
      }));
    } else if (currentLevel === 'adSets') {
      setSelectedItems((prev) => ({
        ...prev,
        adSets: isChecked ? ids : [],
      }));
    } else if (currentLevel === 'ads') {
      setSelectedItems((prev) => ({
        ...prev,
        ads: isChecked ? ids : [],
      }));
    }
  };

  const handleDateChange = (e) => {
    setDatePreset('');
    const newDateRange = {
      ...dateRange,
      [e.target.name]: e.target.value,
    };
    setDateRange(newDateRange);
    localStorage.setItem(
      `dateRange_${userEmail}`,
      JSON.stringify(newDateRange)
    );
  };

  const handleFetchMetrics = () => {
    if (!dateRange.since || !dateRange.until) {
      toast.warning('Por favor, preencha a data inicial e final.', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }

    if (currentLevel === 'campaigns') {
      fetchMetrics(campaigns, 'campaigns');
    } else if (currentLevel === 'adSets') {
      fetchMetrics(adSets, 'adSets');
    } else if (currentLevel === 'ads') {
      fetchMetrics(ads, 'ads');
    }
  };

  const handleDatePresetClick = (preset) => {
    setDatePreset(preset);
    localStorage.setItem(`datePreset_${userEmail}`, preset);
    let since = '';
    let until = '';

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const last7Days = new Date(today);
    last7Days.setDate(last7Days.getDate() - 6);
    const last30Days = new Date(today);
    last30Days.setDate(last30Days.getDate() - 29);

    switch (preset) {
      case 'today':
        since = today.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      case 'yesterday':
        since = yesterday.toISOString().split('T')[0];
        until = yesterday.toISOString().split('T')[0];
        break;
      case 'last_7d':
        since = last7Days.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      case 'last_30d':
        since = last30Days.toISOString().split('T')[0];
        until = today.toISOString().split('T')[0];
        break;
      default:
        break;
    }

    setDateRange({ since, until });
    localStorage.setItem(
      `dateRange_${userEmail}`,
      JSON.stringify({ since, until })
    );

    handleFetchMetrics();
  };

  const formatMetricValue = (key, value) => {
    if (['spend', 'cpm', 'link_click_cpc', 'costPerResult'].includes(key)) {
      return value ? `R$ ${parseFloat(value).toFixed(2)}` : 'R$ 0.00';
    } else if (
      ['link_click_ctr', 'hookRate', 'holdRate', 'roi'].includes(key)
    ) {
      return value ? `${parseFloat(value).toFixed(2)}%` : '0.00%';
    } else if (key === 'frequency') {
      return value ? parseFloat(value).toFixed(2) : '0.00';
    } else if (
      [
        'totalResults',
        'inline_link_clicks',
        'impressions',
        'reach',
        'video3SecViews',
        'video75Views',
      ].includes(key)
    ) {
      return value ? parseInt(value, 10).toLocaleString() : '0';
    } else if (key === 'budget') {
      return value ? `R$ ${parseFloat(value).toFixed(2)}` : 'R$ 0.00';
    } else {
      return value || 'Não especificado';
    }
  };

  const isWhatsAppConversion = (item) => {
    if (currentLevel === 'campaigns') {
      const relatedAdSets = adSets.filter(
        (adSet) => adSet.campaign_id === item.id
      );
      return relatedAdSets.some(
        (adSet) => adSet.destination_type === 'WHATSAPP'
      );
    } else if (currentLevel === 'adSets') {
      return item.destination_type === 'WHATSAPP';
    } else if (currentLevel === 'ads') {
      const adSet = adSets.find((adSet) => adSet.id === item.adset_id);
      if (adSet) {
        return adSet.destination_type === 'WHATSAPP';
      }
    }
    return false;
  };

  const getAdditionalPhrase = (item, metric) => {
    if (
      currentLevel === 'campaigns' ||
      currentLevel === 'adSets' ||
      currentLevel === 'ads'
    ) {
      if (metric === 'costPerResult') {
        if (isWhatsAppConversion(item)) {
          return 'Custo por conversa iniciada';
        }
      } else if (metric === 'totalResults') {
        if (isWhatsAppConversion(item)) {
          return 'Conversa iniciada';
        }
      }
    }
    return null;
  };

  const handleLogout = () => {
    const clearMetricsCache = () => {
      const metricsKeys = [
        'adAccounts_',
        'campaigns_',
        'adSets_',
        'ads_',
        'selectedMetricsColors',
      ];
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (
          key &&
          metricsKeys.some(
            (prefix) => key.startsWith(prefix) && key.includes(userEmail)
          )
        ) {
          keysToRemove.push(key);
        }
      }
      keysToRemove.forEach((key) => localStorage.removeItem(key));

      const sessionKeysToRemove = [];
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);
        if (
          key &&
          metricsKeys.some(
            (prefix) => key.startsWith(prefix) && key.includes(userEmail)
          )
        ) {
          sessionKeysToRemove.push(key);
        }
      }
      sessionKeysToRemove.forEach((key) => sessionStorage.removeItem(key));
    };

    logout();
    localStorage.removeItem(`facebookToken_${userEmail}`);
    sessionStorage.removeItem(`facebookToken_${userEmail}`);
    clearMetricsCache();
    navigate('/login');
  };

  const handleBackToMenu = () => {
    navigate('/ferramentas');
  };

  const handleFacebookLoginSuccess = (token) => {
    localStorage.setItem(`facebookToken_${userEmail}`, token);
    setFacebookToken(token);
    toast.success('Login realizado com sucesso!', {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  const userName = getUserName() || 'Usuário';

  const userPhoto =
    localStorage.getItem(`${userEmail}-fotoPerfil`) ||
    'https://via.placeholder.com/40';

  const openFacebookManager = () => {
    if (!selectedAccount) {
      window.open('https://business.facebook.com/adsmanager', '_blank');
      return;
    }

    let baseUrl = 'https://business.facebook.com/adsmanager/manage';
    let queryParams = `act=${selectedAccount}`;

    if (dateRange.since && dateRange.until) {
      queryParams += `&time_range[since]=${dateRange.since}&time_range[until]=${dateRange.until}`;
    }

    if (currentLevel === 'campaigns' && selectedItems.campaigns.length > 0) {
      const campaignIds = selectedItems.campaigns.join(',');
      queryParams += `&campaign_ids=[${campaignIds}]`;
      baseUrl += '/campaigns';
    } else if (currentLevel === 'adSets' && selectedItems.adSets.length > 0) {
      const adSetIds = selectedItems.adSets.join(',');
      queryParams += `&adset_ids=[${adSetIds}]`;
      baseUrl += '/adsets';
    } else if (currentLevel === 'ads' && selectedItems.ads.length > 0) {
      const adIds = selectedItems.ads.join(',');
      queryParams += `&ad_ids=[${adIds}]`;
      baseUrl += '/ads';
    } else {
      baseUrl += '/accounts';
    }

    window.open(`${baseUrl}?${queryParams}`, '_blank');
  };

  const getTimeSinceLastUpdate = () => {
    if (!lastUpdateTime) return '';
    const now = new Date();
    const diffInMinutes = Math.floor((now - lastUpdateTime) / (1000 * 60));
    if (diffInMinutes < 1) return 'Atualizado há menos de 1 minuto';
    return `Atualizado há ${diffInMinutes} minutos`;
  };

  const refreshData = () => {
    if (currentLevel === 'campaigns') {
      fetchCampaigns(selectedAccount);
    } else if (currentLevel === 'adSets') {
      const campaignsToFetch =
        selectedItems.campaigns.length > 0
          ? selectedItems.campaigns
          : campaigns.map((campaign) => campaign.id);
      fetchAdSets(campaignsToFetch);
    } else if (currentLevel === 'ads') {
      const adSetsToFetch =
        selectedItems.adSets.length > 0
          ? selectedItems.adSets
          : adSets.map((adSet) => adSet.id);
      fetchAds(adSetsToFetch);
    }
  };

  const selectedCampaignInfo = () => {
    if (selectedItems.campaigns.length === 1) {
      const campaign = campaigns.find(
        (c) => c.id === selectedItems.campaigns[0]
      );
      if (campaign) {
        return {
          type: translateCampaignType(campaign.buying_type),
          objective:
            campaignObjectives[campaign.objective] || campaign.objective,
          isCBO: campaign.is_campaign_budget_optimization ? 'CBO' : 'ABO',
          budget: getBudgetValue(campaign),
        };
      }
    }
    return {
      type: 'Múltiplos',
      objective: 'Múltiplos',
      isCBO: 'Múltiplos',
      budget: 0,
    };
  };

  const selectedAdSetInfo = () => {
    if (selectedItems.adSets.length === 1) {
      const adSet = adSets.find((a) => a.id === selectedItems.adSets[0]);
      if (adSet) {
        return {
          conversionType:
            destinationTypes[adSet.destination_type] ||
            adSet.destination_type,
        };
      }
    }
    return {
      conversionType: 'Múltiplos',
    };
  };

  const translateCampaignType = (type) => {
    switch (type) {
      case 'AUCTION':
        return 'Leilão';
      case 'FIXED':
        return 'Fixo';
      default:
        return type;
    }
  };

  const translateCampaignObjective = (objective) => {
    switch (objective) {
      case 'OUTCOME_ENGAGEMENT':
        return 'Engajamento';
      case 'OUTCOME_SALES':
        return 'Vendas';
      case 'OUTCOME_LEAD_GENERATION':
        return 'Geração de Leads';
      default:
        return objective;
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(selectedMetrics);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setSelectedMetrics(items);
    localStorage.setItem(`selectedMetrics_${userEmail}`, JSON.stringify(items));
  };

  const handleMouseDown = (e, metric) => {
    e.preventDefault();
    setIsResizing(true);
    setCurrentMetric(metric);
  };

  const handleMouseMove = (e) => {
    if (!isResizing || !currentMetric) return;
    e.preventDefault();
    const thElement = document.querySelector(
      `th[data-metric="${currentMetric}"]`
    );
    if (!thElement) return;
    const width = e.clientX - thElement.getBoundingClientRect().left;
    setColumnWidths((prev) => ({
      ...prev,
      [currentMetric]: width > 50 ? width : 50,
    }));
  };

  const handleMouseUp = () => {
    setIsResizing(false);
    setCurrentMetric(null);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  const handleSendMetricsToChat = async () => {
    if (selectedMetrics.length === 0) {
      toast.warning('Por favor, selecione pelo menos uma métrica para análise.', {
        position: 'top-right',
        autoClose: 3000,
      });
      return;
    }

    let additionalData = [];

    const formatMetrics = (itemMetrics) => {
      let formattedMetrics = {};
      Object.keys(itemMetrics).forEach((key) => {
        formattedMetrics[key] = formatMetricValue(key, itemMetrics[key]);
      });
      return formattedMetrics;
    };

    if (currentLevel === 'campaigns') {
      const selectedCampaigns = campaigns.filter((c) =>
        selectedItems.campaigns.includes(c.id)
      );
      additionalData = selectedCampaigns.map((campaign) => {
        const isWhatsApp = isWhatsAppConversion(campaign);
        const campaignMetrics = formatMetrics(metrics[campaign.id]);

        if (isWhatsApp) {
          if (campaignMetrics.totalResults !== undefined) {
            campaignMetrics.totalResultsPhrase = 'Conversa Iniciada';
          }
          if (campaignMetrics.costPerResult !== undefined) {
            campaignMetrics.costPerResultPhrase = 'Custo por Conversa Iniciada';
          }
        }

        const relatedAdSets = adSets.filter(
          (adSet) => adSet.campaign_id === campaign.id
        );

        const adSetsData = relatedAdSets.map((adSet) => {
          const campaignForAdSet = campaigns.find(
            (c) => c.id === adSet.campaign_id
          );

          let budgetValue = 0;
          if (campaignForAdSet && campaignForAdSet.is_campaign_budget_optimization) {
            budgetValue = getBudgetValue(campaignForAdSet);
          } else {
            budgetValue = metrics[adSet.id]?.budget || 0;
          }

          const relatedAds = ads.filter((ad) => ad.adset_id === adSet.id);
          return {
            id: adSet.id,
            name: adSet.name,
            conversionType:
              destinationTypes[adSet.destination_type] ||
              adSet.destination_type,
            budget: formatMetricValue('budget', budgetValue),
            budget_utilization: metrics[adSet.id]?.budget_utilization || 'N/A',
            campaignObjective: translateCampaignObjective(campaign.objective),
            campaign_objective: translateCampaignObjective(campaign.objective),
            budgetOptimization: campaign.is_campaign_budget_optimization
              ? 'CBO'
              : 'ABO',
            metrics: formatMetrics(metrics[adSet.id]),
            ads: relatedAds.map((ad) => ({
              id: ad.id,
              name: ad.name,
              creativeType: ad.creative_type,
              budget: formatMetricValue('budget', metrics[ad.id]?.budget),
              budget_utilization: metrics[ad.id]?.budget_utilization || 'N/A',
              campaignObjective: translateCampaignObjective(campaign.objective),
              campaign_objective: translateCampaignObjective(campaign.objective),
              budgetOptimization: campaign.is_campaign_budget_optimization
                ? 'CBO'
                : 'ABO',
              metrics: formatMetrics(metrics[ad.id]),
              dateRange: dateRange,
            })),
            dateRange: dateRange,
          };
        });

        return {
          id: campaign.id,
          name: campaign.name,
          type: translateCampaignType(campaign.buying_type),
          objective: translateCampaignObjective(campaign.objective),
          budgetOptimization: campaign.is_campaign_budget_optimization
            ? 'CBO'
            : 'ABO',
          budget: formatMetricValue('budget', campaignMetrics.budget),
          budget_utilization: campaignMetrics.budget_utilization || 'N/A',
          campaign_objective: translateCampaignObjective(campaign.objective),
          metrics: campaignMetrics,
          adSets: adSetsData,
          dateRange: dateRange,
        };
      });
    } else if (currentLevel === 'adSets') {
      const selectedAdSets = adSets.filter((a) =>
        selectedItems.adSets.includes(a.id)
      );
      additionalData = selectedAdSets.map((adSet) => {
        const isWhatsApp = isWhatsAppConversion(adSet);
        const adSetMetrics = formatMetrics(metrics[adSet.id]);

        const campaignForAdSet = campaigns.find(
          (c) => c.id === adSet.campaign_id
        );

        let budgetValue = 0;
        if (campaignForAdSet && campaignForAdSet.is_campaign_budget_optimization) {
          budgetValue = getBudgetValue(campaignForAdSet);
        } else {
          budgetValue = adSetMetrics.budget || 0;
        }

        if (isWhatsApp) {
          if (adSetMetrics.totalResults !== undefined) {
            adSetMetrics.totalResultsPhrase = 'Conversa Iniciada';
          }
          if (adSetMetrics.costPerResult !== undefined) {
            adSetMetrics.costPerResultPhrase = 'Custo por Conversa Iniciada';
          }
        }

        const relatedAds = ads.filter((ad) => ad.adset_id === adSet.id);

        return {
          id: adSet.id,
          name: adSet.name,
          conversionType:
            destinationTypes[adSet.destination_type] ||
            adSet.destination_type,
          budget: formatMetricValue('budget', budgetValue),
          budget_utilization: adSetMetrics.budget_utilization || 'N/A',
          campaignObjective: translateCampaignObjective(
            campaigns.find((c) => c.id === adSet.campaign_id)?.objective ||
              'N/A'
          ),
          campaign_objective: translateCampaignObjective(
            campaigns.find((c) => c.id === adSet.campaign_id)?.objective ||
              'N/A'
          ),
          budgetOptimization: campaigns.find((c) => c.id === adSet.campaign_id)
            ?.is_campaign_budget_optimization
            ? 'CBO'
            : 'ABO',
          metrics: adSetMetrics,
          ads: relatedAds.map((ad) => ({
            id: ad.id,
            name: ad.name,
            creativeType: ad.creative_type,
            budget: formatMetricValue('budget', metrics[ad.id]?.budget),
            budget_utilization: metrics[ad.id]?.budget_utilization || 'N/A',
            campaignObjective: translateCampaignObjective(
              campaigns.find((c) => c.id === adSet.campaign_id)?.objective ||
                'N/A'
            ),
            campaign_objective: translateCampaignObjective(
              campaigns.find((c) => c.id === adSet.campaign_id)?.objective ||
                'N/A'
            ),
            budgetOptimization: campaigns.find((c) => c.id === adSet.campaign_id)
              ?.is_campaign_budget_optimization
              ? 'CBO'
              : 'ABO',
            metrics: formatMetrics(metrics[ad.id]),
            dateRange: dateRange,
          })),
          dateRange: dateRange,
        };
      });
    } else if (currentLevel === 'ads') {
      const selectedAds = ads.filter((a) => selectedItems.ads.includes(a.id));
      additionalData = selectedAds.map((ad) => {
        const adSet = adSets.find((adSet) => adSet.id === ad.adset_id);
        const campaign = campaigns.find((c) => c.id === adSet?.campaign_id);

        const isWhatsApp = isWhatsAppConversion(ad);
        const adMetrics = formatMetrics(metrics[ad.id]);

        if (isWhatsApp) {
          if (adMetrics.totalResults !== undefined) {
            adMetrics.totalResultsPhrase = 'Conversa Iniciada';
          }
          if (adMetrics.costPerResult !== undefined) {
            adMetrics.costPerResultPhrase = 'Custo por Conversa Iniciada';
          }
        }

        return {
          id: ad.id,
          name: ad.name,
          creativeType: ad.creative_type,
          budget: formatMetricValue('budget', metrics[ad.id]?.budget),
          budget_utilization: metrics[ad.id]?.budget_utilization || 'N/A',
          campaignObjective: campaign
            ? translateCampaignObjective(campaign.objective)
            : 'N/A',
          campaign_objective: campaign
            ? translateCampaignObjective(campaign.objective)
            : 'N/A',
          budgetOptimization: campaign
            ? campaign.is_campaign_budget_optimization
              ? 'CBO'
              : 'ABO'
            : 'N/A',
          adSetName: adSet ? adSet.name : 'N/A',
          conversionType: adSet
            ? destinationTypes[adSet.destination_type] || adSet.destination_type
            : 'N/A',
          metrics: adMetrics,
          dateRange: dateRange,
        };
      });
    }

    const chatMetricsData = {
      level: currentLevel,
      items: additionalData,
      selectedMetrics: selectedMetrics,
      analysisPeriod: `${dateRange.since} a ${dateRange.until}`,
    };

    setMetricsForChat(chatMetricsData);
    toast.success('Métricas enviadas para análise no chat!', {
      position: 'top-right',
      autoClose: 3000,
    });
  };

  // --------------- NOVAS FUNÇÕES PARA ATUALIZAR ESTADO + CACHE ---------------

  // Função para atualizar localmente um item no estado e também no cache
  const updateLocalItem = (id, updates) => {
    if (currentLevel === 'campaigns') {
      setCampaigns((prev) => {
        const updatedCampaigns = prev.map((c) =>
          c.id === id ? { ...c, ...updates } : c
        );
        // Atualiza o cache
        setCachedData(`campaigns_${selectedAccount}_${userEmail}`, updatedCampaigns);
        return updatedCampaigns;
      });
    } else if (currentLevel === 'adSets') {
      setAdSets((prev) => {
        const updatedAdSets = prev.map((a) =>
          a.id === id ? { ...a, ...updates } : a
        );
        // Descobrindo a campanha que esse adset pertence
        // (para atualizar no cache correto)
        if (updatedAdSets.length > 0) {
          const found = updatedAdSets.find((a) => a.id === id);
          if (found) {
            // Se existir, vamos usar found.campaign_id
            const campaignId = found.campaign_id;
            // Precisamos pegar do localStorage o array de adSets da campaign
            const cachedAdSets = getCachedData(`adSets_${campaignId}_${userEmail}`);
            if (cachedAdSets) {
              // Atualiza somente esse adset
              const newCached = cachedAdSets.map((adSet) =>
                adSet.id === id ? { ...adSet, ...updates } : adSet
              );
              setCachedData(`adSets_${campaignId}_${userEmail}`, newCached);
            }
          }
        }
        return updatedAdSets;
      });
    } else if (currentLevel === 'ads') {
      setAds((prev) => {
        const updatedAds = prev.map((ad) =>
          ad.id === id ? { ...ad, ...updates } : ad
        );
        // Descobrindo o adset ao qual o ad pertence, para atualizar o cache
        if (updatedAds.length > 0) {
          const foundAd = updatedAds.find((ad) => ad.id === id);
          if (foundAd) {
            const adSetId = foundAd.adset_id;
            // Pega do localStorage o array de ads do adSet
            const cachedAds = getCachedData(`ads_${adSetId}_${userEmail}`);
            if (cachedAds) {
              const newCachedAds = cachedAds.map((adItem) =>
                adItem.id === id ? { ...adItem, ...updates } : adItem
              );
              setCachedData(`ads_${adSetId}_${userEmail}`, newCachedAds);
            }
          }
        }
        return updatedAds;
      });
    }
  };

  // Abertura do pop-up de edição de nome
  const startEditingName = (item) => {
    setEditNameItemId(item.id);
    setEditNameValue(item.name);
    setShowEditNamePopup(true);
  };

  // Salvar edição de nome
  const saveEditedName = async () => {
    if (!editNameItemId) return;
    let nivel;
    if (currentLevel === 'campaigns') nivel = 'campaign';
    else if (currentLevel === 'adSets') nivel = 'adset';
    else nivel = 'ad';

    await editarNomeItem(facebookToken, editNameItemId, nivel, editNameValue);

    // Atualiza localmente o nome
    updateLocalItem(editNameItemId, { name: editNameValue });

    setShowEditNamePopup(false);
    setEditNameItemId(null);
    setEditNameValue('');
  };

  // Popup de status (podemos ter um botão global para ativar/desativar)
  const toggleStatusBulk = async () => {
    if (currentLevel === 'campaigns') {
      for (const id of selectedItems.campaigns) {
        const item = campaigns.find((c) => c.id === id);
        let novoStatus = item.status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        await alterarStatusItem(facebookToken, item.id, 'campaign', novoStatus);
        updateLocalItem(item.id, { status: novoStatus });
      }
    } else if (currentLevel === 'adSets') {
      for (const id of selectedItems.adSets) {
        const adSet = adSets.find((a) => a.id === id);
        let novoStatus = adSet.status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        await alterarStatusItem(facebookToken, adSet.id, 'adset', novoStatus);
        updateLocalItem(adSet.id, { status: novoStatus });
      }
    } else if (currentLevel === 'ads') {
      for (const id of selectedItems.ads) {
        const adItem = ads.find((a) => a.id === id);
        let novoStatus = adItem.status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
        await alterarStatusItem(facebookToken, adItem.id, 'ad', novoStatus);
        updateLocalItem(adItem.id, { status: novoStatus });
      }
    }
    toast.success('Status atualizado com sucesso!');
    refreshData();
  };

  // Botão para abrir pop-up de edição de orçamento (individual)
  const startEditingBudget = (item) => {
    setEditBudgetItemId(item.id);
    const budgetVal = metrics[item.id]?.budget || 0;
    setEditBudgetValue(budgetVal.toString());
    setIsLifetimeBudget(false);
    setShowEditBudgetPopup(true);
  };

  // Salvar orçamento (individual)
  const saveEditedBudget = async () => {
    let nivel;
    if (currentLevel === 'campaigns') nivel = 'campaign';
    else nivel = 'adset';

    await alterarOrcamentoItem(
      facebookToken,
      editBudgetItemId,
      nivel,
      parseFloat(editBudgetValue),
      isLifetimeBudget
    );

    // Atualiza localmente
    const field = isLifetimeBudget ? 'lifetime_budget' : 'daily_budget';
    const valueInCents = Math.round(parseFloat(editBudgetValue) * 100);
    updateLocalItem(editBudgetItemId, { [field]: valueInCents.toString() });

    setShowEditBudgetPopup(false);
    setEditBudgetItemId(null);
    setEditBudgetValue('');
    setIsLifetimeBudget(false);
  };

  // Pop-up de duplicação
  const confirmDuplicate = async () => {
    await duplicarSelecaoCompleta(facebookToken, selectedItems, currentLevel, selectedAccount);
    setShowDuplicatePopup(false);
    // Para mostrar imediatamente a duplicação, recarregamos:
    refreshData();
  };

  // Pop-up de exclusão
  const confirmDelete = async () => {
    await excluirSelecaoCompleta(facebookToken, selectedItems, currentLevel, selectedAccount);
    setShowDeletePopup(false);
    refreshData();
  };

  // Pop-up para alterar orçamento em massa
  const alterBulkBudget = async () => {
    let nivel;
    if (currentLevel === 'campaigns') nivel = 'campaign';
    else if (currentLevel === 'adSets') nivel = 'adset';
    else {
      toast.error('Alterar orçamento para anúncios individualmente, selecione Conjuntos ou Campanhas.');
      setShowBulkBudgetPopup(false);
      return;
    }

    const selectedIds =
      currentLevel === 'campaigns'
        ? selectedItems.campaigns
        : selectedItems.adSets;

    for (const id of selectedIds) {
      await alterarOrcamentoItem(
        facebookToken,
        id,
        nivel,
        parseFloat(bulkBudgetValue),
        isLifetimeBulk
      );

      const field = isLifetimeBulk ? 'lifetime_budget' : 'daily_budget';
      const valueInCents = Math.round(parseFloat(bulkBudgetValue) * 100);
      updateLocalItem(id, { [field]: valueInCents.toString() });
    }

    setShowBulkBudgetPopup(false);
    setBulkBudgetValue('');
    setIsLifetimeBulk(false);
    refreshData();
  };

  return (
    <div className="facebook-ads-dashboard-container">
      <ToastContainer />

      {/* POP-UP DE EDITAR NOME */}
      {showEditNamePopup && (
        <div className="fad-popup-overlay">
          <div className="fad-popup">
            <h2>Editar Nome</h2>
            <input
              type="text"
              value={editNameValue}
              onChange={(e) => setEditNameValue(e.target.value)}
            />
            <div style={{ marginTop: '20px' }}>
              <button onClick={saveEditedName} className="fad-button confirm-button">
                Publicar
              </button>
              <button
                onClick={() => {
                  setShowEditNamePopup(false);
                  setEditNameItemId(null);
                }}
                className="fad-button cancel-button"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* POP-UP DE EDITAR ORÇAMENTO */}
      {showEditBudgetPopup && (
        <div className="fad-popup-overlay">
          <div className="fad-popup">
            <h2>Editar Orçamento</h2>
            <input
              type="number"
              step="0.01"
              value={editBudgetValue}
              onChange={(e) => setEditBudgetValue(e.target.value)}
            />
            <div style={{ margin: '10px 0' }}>
              <label style={{ marginRight: '8px' }}>
                <input
                  type="checkbox"
                  checked={isLifetimeBudget}
                  onChange={() => setIsLifetimeBudget(!isLifetimeBudget)}
                />
                Usar Lifetime Budget?
              </label>
            </div>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={saveEditedBudget}
                className="fad-button confirm-button"
              >
                Publicar
              </button>
              <button
                onClick={() => {
                  setShowEditBudgetPopup(false);
                  setEditBudgetItemId(null);
                }}
                className="fad-button cancel-button"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* POP-UP DE DUPLICAR */}
      {showDuplicatePopup && (
        <div className="fad-popup-overlay">
          <div className="fad-popup">
            <h2>Duplicar Seleção</h2>
            <p>Digite quantas cópias deseja criar (cada item selecionado será duplicado esse número de vezes):</p>
            <input
              type="number"
              value={duplicateCopiesCount}
              onChange={(e) => setDuplicateCopiesCount(e.target.value)}
              min="1"
              style={{ width: '80px', margin: '10px 0' }}
            />
            <p style={{ fontSize: '0.9rem' }}>
              *Observação: a API atual não implementa múltiplas cópias simultâneas,  
              mas deixamos o campo de exemplo conforme solicitado.
            </p>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={confirmDuplicate}
                className="fad-button confirm-button"
              >
                Duplicar
              </button>
              <button
                onClick={() => setShowDuplicatePopup(false)}
                className="fad-button cancel-button"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* POP-UP DE EXCLUIR */}
      {showDeletePopup && (
        <div className="fad-popup-overlay">
          <div className="fad-popup">
            <h2>Excluir Selecionados</h2>
            <p>Tem certeza de que deseja excluir os itens selecionados?</p>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={confirmDelete}
                className="fad-button confirm-button"
                style={{ backgroundColor: '#dc2626', color: '#fff' }}
              >
                Sim
              </button>
              <button
                onClick={() => setShowDeletePopup(false)}
                className="fad-button cancel-button"
                style={{ backgroundColor: '#f3f4f6', color: '#000' }}
              >
                Não
              </button>
            </div>
          </div>
        </div>
      )}

      {/* POP-UP DE ALTERAR ORÇAMENTO EM MASSA */}
      {showBulkBudgetPopup && (
        <div className="fad-popup-overlay">
          <div className="fad-popup">
            <h2>Alterar Orçamento em Massa</h2>
            <p>Informe o valor do orçamento para todos os itens selecionados:</p>
            <input
              type="number"
              step="0.01"
              value={bulkBudgetValue}
              onChange={(e) => setBulkBudgetValue(e.target.value)}
              style={{ margin: '10px 0' }}
            />
            <div style={{ margin: '10px 0' }}>
              <label style={{ marginRight: '8px' }}>
                <input
                  type="checkbox"
                  checked={isLifetimeBulk}
                  onChange={() => setIsLifetimeBulk(!isLifetimeBulk)}
                />
                Usar Lifetime Budget?
              </label>
            </div>
            <div style={{ marginTop: '20px' }}>
              <button
                onClick={alterBulkBudget}
                className="fad-button confirm-button"
              >
                Confirmar
              </button>
              <button
                onClick={() => setShowBulkBudgetPopup(false)}
                className="fad-button cancel-button"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      <header className="facebook-ads-dashboard-header">
        <div className="header-left">
          <img src={logo} alt="Logo" className="header-logo" />
          <button className="back-button" onClick={handleBackToMenu}>
            <FaArrowLeft /> Voltar para Menu
          </button>
        </div>
        <div className="header-right">
          <button className="fad-button refresh-btn" onClick={refreshData}>
            <FaSyncAlt />
          </button>
          <div className="facebook-login-container-small">
            <FacebookLogin onLoginSuccess={handleFacebookLoginSuccess} />
          </div>
          <div className="user-info">
            <img
              src={userPhoto}
              alt="Avatar do Usuário"
              className="user-avatar"
            />
            <span className="user-name">{userName}</span>
          </div>
          <button className="logout-button" onClick={handleLogout}>
            Sair
          </button>
        </div>
      </header>

      <div className="facebook-ads-dashboard-content">
        <main className="fad-main-content">
          <div className="fad-main-header">
            <div className="fad-main-header-left">
              <h1 className="fad-title">Gerenciamento de Anúncios</h1>
            </div>
            <div className="fad-main-header-right">
              <button
                className="fad-button open-manager-btn"
                onClick={openFacebookManager}
              >
                <FaBullhorn /> Abrir no Gerenciador
              </button>
              <button
                className="fad-button duplicate-btn"
                onClick={() => setShowDuplicatePopup(true)}
                disabled={selectedItems[currentLevel].length === 0}
              >
                <FaCopy /> Duplicar
              </button>

              {/* Botão de Alterar Orçamento em Massa */}
              <button
                className="fad-button budget-btn"
                onClick={() => setShowBulkBudgetPopup(true)}
                disabled={selectedItems[currentLevel].length === 0}
                title="Alterar orçamento em massa"
              >
                <FaMoneyBillWave /> Alterar Orçamento
              </button>

              {/* Botão de Ativar/Desativar em Massa */}
              <button
                className="fad-button toggle-btn"
                onClick={toggleStatusBulk}
                disabled={selectedItems[currentLevel].length === 0}
                title="Ativar/Desativar seleção"
              >
                <FaToggleOn /> Ativar/Desativar
              </button>

              {/* Botão de Filtrar Selecionadas */}
              <button
                className="fad-button filter-selected-btn"
                onClick={() => setFilterOnlySelected(!filterOnlySelected)}
                title="Filtrar apenas os itens selecionados"
              >
                <FaFilter />
                {filterOnlySelected ? ' Todas' : ' Selecionadas'}
              </button>

              {/* Botão de Excluir */}
              <button
                className="fad-button delete-btn"
                onClick={() => setShowDeletePopup(true)}
                disabled={selectedItems[currentLevel].length === 0}
                title="Excluir seleção"
              >
                <FaTrash /> Excluir
              </button>

              <button
                className="fad-button metrics-selector-btn"
                onClick={() => setShowMetricsSelector(true)}
              >
                Selecionar Métricas
              </button>
              <button
                className="fad-button send-to-chat-btn"
                onClick={handleSendMetricsToChat}
                disabled={
                  loading ||
                  selectedItems[currentLevel].length === 0 ||
                  selectedMetrics.length === 0
                }
                title="Enviar Métricas para o Chat"
              >
                <FaChartLine /> Enviar para Chat
              </button>
              <div className="fad-refresh-container">
                <span className="fad-update-text">
                  {getTimeSinceLastUpdate()}
                </span>
              </div>
            </div>
          </div>

          <div className="fad-navigation-buttons">
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'campaigns' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                setCurrentLevel('campaigns');
                setSelectedItems({ campaigns: [], adSets: [], ads: [] });
                await fetchCampaigns(selectedAccount);
              }}
              disabled={loading}
            >
              Campanha ({campaigns.length})
            </button>
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'adSets' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                if (selectedItems.campaigns.length > 0) {
                  setCurrentLevel('adSets');
                  await fetchAdSets(selectedItems.campaigns);
                } else {
                  toast.warning(
                    'Por favor, selecione uma campanha primeiro.',
                    {
                      position: 'top-right',
                      autoClose: 3000,
                    }
                  );
                }
              }}
              disabled={loading || selectedItems.campaigns.length === 0}
            >
              Conjunto de Anúncio ({adSets.length})
            </button>
            <button
              className={`fad-button nav-btn ${
                currentLevel === 'ads' ? 'active-nav' : ''
              }`}
              onClick={async () => {
                if (selectedItems.adSets.length > 0) {
                  setCurrentLevel('ads');
                  await fetchAds(selectedItems.adSets);
                } else {
                  toast.warning(
                    'Por favor, selecione um conjunto de anúncios primeiro.',
                    {
                      position: 'top-right',
                      autoClose: 3000,
                    }
                  );
                }
              }}
              disabled={loading || selectedItems.adSets.length === 0}
            >
              Anúncio ({ads.length})
            </button>
          </div>

          <div className="fad-filters">
            <div className="fad-filter-item">
              <label className="fad-filter-label">Nome</label>
              <input
                type="text"
                placeholder="Filtrar por nome"
                className="fad-filter-input"
                onChange={(e) => {
                  setNameFilter(e.target.value.toLowerCase());
                }}
              />
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Status</label>
              <select
                className="fad-filter-select"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
              >
                <option>Qualquer</option>
                <option>Ativa</option>
                <option>Inativa</option>
              </select>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Data</label>
              <div className="fad-date-selector-wrapper">
                <motion.div
                  className="fad-date-selector"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <button
                    onClick={() => setShowDateSelector(!showDateSelector)}
                    className="fad-button date-toggle-button"
                  >
                    <FaCalendarAlt />
                    {showDateSelector
                      ? ' Esconder Intervalo de Datas'
                      : ' Selecionar Intervalo de Datas'}
                  </button>
                  {showDateSelector && (
                    <motion.div
                      className="fad-date-selector-content"
                      initial={{ height: 0 }}
                      animate={{ height: 'auto' }}
                      transition={{ duration: 0.5 }}
                    >
                      <div className="fad-date-inputs">
                        <input
                          type="date"
                          name="since"
                          value={dateRange.since}
                          onChange={(e) => {
                            handleDateChange(e);
                          }}
                          className="fad-filter-input"
                        />
                        <input
                          type="date"
                          name="until"
                          value={dateRange.until}
                          onChange={(e) => {
                            handleDateChange(e);
                          }}
                          className="fad-filter-input"
                        />
                      </div>
                      <div className="fad-date-preset-buttons">
                        <button
                          onClick={() => {
                            handleDatePresetClick('today');
                          }}
                          className="fad-button small-button"
                        >
                          Hoje
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('yesterday');
                          }}
                          className="fad-button small-button"
                        >
                          Ontem
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('last_7d');
                          }}
                          className="fad-button small-button"
                        >
                          Últimos 7 dias
                        </button>
                        <button
                          onClick={() => {
                            handleDatePresetClick('last_30d');
                          }}
                          className="fad-button small-button"
                        >
                          Últimos 30 dias
                        </button>
                      </div>
                      <button
                        className="fad-button fetch-metrics-btn"
                        onClick={handleFetchMetrics}
                      >
                        Buscar
                      </button>
                    </motion.div>
                  )}
                </motion.div>
              </div>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Conta de Anúncio</label>
              <select
                className="fad-filter-select"
                value={selectedAccount || ''}
                onChange={(e) => fetchCampaigns(e.target.value)}
              >
                <option value="">Selecione uma conta</option>
                {accounts.map((account) => (
                  <option key={account.id} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="fad-filter-item">
              <label className="fad-filter-label">Produto</label>
              <select className="fad-filter-select" onChange={(e) => {}}>
                <option>Qualquer</option>
              </select>
            </div>
          </div>

          {currentLevel === 'campaigns' && (
            <div className="fad-additional-info">
              <div className="fad-info-item">
                <span className="fad-info-label">Tipo de Campanha:</span>
                <span className="fad-info-value">
                  {selectedCampaignInfo().type}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Objetivo da Campanha:</span>
                <span className="fad-info-value">
                  {translateCampaignObjective(selectedCampaignInfo().objective)}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Otimização de Orçamento:</span>
                <span className="fad-info-value">
                  {selectedCampaignInfo().isCBO}
                </span>
              </div>
            </div>
          )}
          {currentLevel === 'adSets' && (
            <div className="fad-additional-info">
              <div className="fad-info-item">
                <span className="fad-info-label">Tipo de Conversão:</span>
                <span className="fad-info-value">
                  {selectedAdSetInfo().conversionType}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Objetivo da Campanha:</span>
                <span className="fad-info-value">
                  {translateCampaignObjective(selectedCampaignInfo().objective)}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Otimização de Orçamento:</span>
                <span className="fad-info-value">
                  {selectedCampaignInfo().isCBO}
                </span>
              </div>
            </div>
          )}
          {currentLevel === 'ads' && (
            <div className="fad-additional-info">
              <div className="fad-info-item">
                <span className="fad-info-label">Objetivo da Campanha:</span>
                <span className="fad-info-value">
                  {translateCampaignObjective(selectedCampaignInfo().objective)}
                </span>
              </div>
              <div className="fad-info-item">
                <span className="fad-info-label">Otimização de Orçamento:</span>
                <span className="fad-info-value">
                  {selectedCampaignInfo().isCBO}
                </span>
              </div>
            </div>
          )}

          <div className="fad-table-container">
            <table className="fad-table">
              <thead>
                <tr>
                  <th className="sticky-column">
                    <input
                      type="checkbox"
                      className="fad-checkbox"
                      checked={areAllItemsSelected()}
                      onChange={handleSelectAll}
                    />
                  </th>
                  <th className="sticky-column">Status</th>
                  <th className="sticky-column">
                    {currentLevel === 'campaigns'
                      ? 'Campanha'
                      : currentLevel === 'adSets'
                      ? 'Conjunto de Anúncio'
                      : 'Anúncio'}
                  </th>
                  {selectedMetrics.map((metric) => (
                    <th
                      key={metric}
                      data-metric={metric}
                      style={{
                        width: columnWidths[metric]
                          ? `${columnWidths[metric]}px`
                          : 'auto',
                      }}
                      className="metric-column"
                    >
                      {metricNames[metric]}
                      <div
                        className="resize-handle"
                        onMouseDown={(e) => handleMouseDown(e, metric)}
                      />
                    </th>
                  ))}
                  <th className="sticky-column">Objetivo da Campanha</th>
                  <th className="sticky-column">Otimização de Orçamento</th>
                  <th className="sticky-column">Orçamento</th>
                </tr>
              </thead>
              <tbody>
                {getFilteredData().map((item) => (
                  <tr
                    key={item.id}
                    className={`fad-table-row ${
                      isItemSelected(item.id) ? 'selected-row' : ''
                    }`}
                  >
                    <td className="sticky-column">
                      <input
                        type="checkbox"
                        className="fad-checkbox"
                        checked={isItemSelected(item.id)}
                        onChange={(e) => handleCheckboxChange(e, item.id)}
                      />
                    </td>
                    <td className="sticky-column">
                      <div
                        className={`fad-status ${
                          item.status === 'ACTIVE' ? 'active' : 'inactive'
                        }`}
                        style={{ display: 'inline-block', marginRight: '8px' }}
                      />
                      {item.status}
                    </td>
                    <td className="sticky-column">
                      <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                        <div
                          className="fad-item-name"
                          onClick={() => handleItemClick(item)}
                          title="Clique para ir ao próximo nível"
                          style={{ cursor: 'pointer' }}
                        >
                          {item.name}
                        </div>
                        <span
                          className="fad-edit-icon"
                          onClick={() => startEditingName(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          ✎
                        </span>
                      </div>
                    </td>
                    {selectedMetrics.map((metric) => (
                      <td
                        key={metric}
                        className={`fad-metric-cell ${
                          selectedMetricsColors[metric] ? 'colored-cell' : ''
                        }`}
                        style={{
                          backgroundColor:
                            selectedMetricsColors[metric] || 'transparent',
                        }}
                      >
                        {metrics[item.id] && metrics[item.id][metric] !== undefined
                          ? formatMetricValue(metric, metrics[item.id][metric])
                          : loading
                          ? 'Carregando...'
                          : '0'}
                        {(metric === 'costPerResult' ||
                          metric === 'totalResults') && (
                          <div className="metric-subtext">
                            {getAdditionalPhrase(item, metric)}
                          </div>
                        )}
                      </td>
                    ))}
                    <td className="campaign-objective-column">
                      {currentLevel === 'campaigns'
                        ? translateCampaignObjective(item.objective)
                        : currentLevel === 'adSets'
                        ? translateCampaignObjective(
                            campaigns.find((c) => c.id === item.campaign_id)
                              ?.objective || 'N/A'
                          )
                        : currentLevel === 'ads'
                        ? translateCampaignObjective(
                            campaigns.find(
                              (c) =>
                                c.id ===
                                adSets.find((a) => a.id === item.adset_id)?.campaign_id
                            )?.objective || 'N/A'
                          )
                        : 'N/A'}
                    </td>
                    <td className="budget-optimization-column">
                      {currentLevel === 'campaigns'
                        ? item.is_campaign_budget_optimization
                          ? 'CBO'
                          : 'ABO'
                        : currentLevel === 'adSets'
                        ? campaigns.find((c) => c.id === item.campaign_id)
                            ?.is_campaign_budget_optimization
                          ? 'CBO'
                          : 'ABO'
                        : currentLevel === 'ads'
                        ? campaigns.find(
                            (c) =>
                              c.id ===
                              adSets.find((a) => a.id === item.adset_id)?.campaign_id
                          )?.is_campaign_budget_optimization
                          ? 'CBO'
                          : 'ABO'
                        : 'N/A'}
                    </td>
                    <td className="budget-column">
                      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                        {metrics[item.id] && metrics[item.id].budget !== undefined ? (
                          <span>
                            {formatMetricValue('budget', metrics[item.id].budget)}
                          </span>
                        ) : loading ? (
                          'Carregando...'
                        ) : (
                          'R$ 0.00'
                        )}
                        <span
                          className="fad-edit-icon"
                          onClick={() => startEditingBudget(item)}
                          style={{ cursor: 'pointer' }}
                        >
                          ✎
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan="3" className="sticky-column">
                    {currentLevel === 'campaigns'
                      ? 'Total de Campanhas'
                      : currentLevel === 'adSets'
                      ? 'Total de Conjuntos de Anúncios'
                      : currentLevel === 'ads'
                      ? 'Total de Anúncios'
                      : 'Totais'}
                  </td>
                  {selectedMetrics.map((metric) => (
                    <td
                      key={metric}
                      className={`fad-metric-cell ${
                        selectedMetricsColors[metric] ? 'colored-cell' : ''
                      }`}
                      style={{
                        backgroundColor:
                          selectedMetricsColors[metric] || 'transparent',
                      }}
                    >
                      {metric === 'frequency'
                        ? formatMetricValue(metric, totalMetrics.frequency)
                        : formatMetricValue(metric, totalMetrics[metric])}
                    </td>
                  ))}
                  <td className="campaign-objective-column">-</td>
                  <td className="budget-optimization-column">-</td>
                  <td className="budget-column">
                    {formatMetricValue('budget', totalMetrics.budget)}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>

          {showMetricsSelector && (
            <div className="fad-modal">
              <div className="fad-modal-content">
                <h3>Selecionar Métricas</h3>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="metrics-list">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className="fad-metrics-list"
                      >
                        {metricOrder
                          .filter((metric) =>
                            metricNames.hasOwnProperty(metric)
                          )
                          .map((metric, index) => (
                            <Draggable
                              key={metric}
                              draggableId={metric}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className="fad-metric-checkbox"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <input
                                    type="checkbox"
                                    id={metric}
                                    name={metric}
                                    checked={selectedMetrics.includes(metric)}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        const newList = [...selectedMetrics, metric];
                                        setSelectedMetrics(newList);
                                        localStorage.setItem(
                                          `selectedMetrics_${userEmail}`,
                                          JSON.stringify(newList)
                                        );
                                      } else {
                                        const newMetrics = selectedMetrics.filter(
                                          (m) => m !== metric
                                        );
                                        setSelectedMetrics(newMetrics);
                                        localStorage.setItem(
                                          `selectedMetrics_${userEmail}`,
                                          JSON.stringify(newMetrics)
                                        );
                                        if (selectedMetricsColors[metric]) {
                                          const newColors = {
                                            ...selectedMetricsColors,
                                          };
                                          delete newColors[metric];
                                          setSelectedMetricsColors(newColors);
                                          localStorage.setItem(
                                            'selectedMetricsColors',
                                            JSON.stringify(newColors)
                                          );
                                        }
                                      }
                                    }}
                                  />
                                  <label htmlFor={metric}>
                                    {metricNames[metric] || metric}
                                  </label>
                                  {selectedMetrics.includes(metric) && (
                                    <MetricColorSelector
                                      metric={metric}
                                      selectedMetricsColors={selectedMetricsColors}
                                      setSelectedMetricsColors={setSelectedMetricsColors}
                                    />
                                  )}
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                <button
                  className="fad-button"
                  onClick={() => setShowMetricsSelector(false)}
                >
                  Fechar
                </button>
              </div>
            </div>
          )}
          <Metricaexplicada />
          <Chat
            metricsData={metricsForChat}
            selectedMetrics={selectedMetrics}
            currentLevel={currentLevel}
            onAnalyzeMetrics={handleSendMetricsToChat}
          />
        </main>
      </div>
    </div>
  );
};

export default FacebookAdsDashboard;
