import React, { useEffect, useState } from "react";
import axios from "axios";
import "./ComentariosYoutuber.css"; // Importa o CSS com prefixo único

const ComentariosYoutube = () => {
  const [comentarios, setComentarios] = useState([]);
  const [loading, setLoading] = useState(true);

  // Chaves via variáveis de ambiente
  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
  const videoId = process.env.REACT_APP_YOUTUBE_VIDEO_ID;

  /**
   * Função para abrir o YouTube em nova aba ou pop-up (desktop).
   */
  const abrirPaginaYouTube = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      // Em dispositivos móveis, abrir em uma nova aba
      window.open(`https://www.youtube.com/watch?v=${videoId}`, "_blank");
    } else {
      // Em desktops, abrir um popup flutuante
      window.open(
        `https://www.youtube.com/watch?v=${videoId}`,
        "YouTubePopup",
        "width=800,height=600"
      );
    }
  };

  useEffect(() => {
    const fetchComentarios = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/commentThreads`,
          {
            params: {
              part: "snippet,replies",
              videoId: videoId,
              key: apiKey,
              maxResults: 10,
            },
          }
        );

        const comentariosFormatados = response.data.items.map((item) => {
          const respostas =
            item.replies?.comments.map((resposta) => ({
              id: resposta.id,
              autor: resposta.snippet.authorDisplayName,
              texto: resposta.snippet.textDisplay,
              imagemPerfil: resposta.snippet.authorProfileImageUrl,
              data: new Date(resposta.snippet.publishedAt).toLocaleDateString(),
            })) || [];

          return {
            id: item.id,
            autor: item.snippet.topLevelComment.snippet.authorDisplayName,
            texto: item.snippet.topLevelComment.snippet.textDisplay,
            imagemPerfil:
              item.snippet.topLevelComment.snippet.authorProfileImageUrl,
            data: new Date(
              item.snippet.topLevelComment.snippet.publishedAt
            ).toLocaleDateString(),
            respostas,
          };
        });

        setComentarios(comentariosFormatados);
      } catch (error) {
        console.error("Erro ao buscar comentários:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchComentarios();
  }, [videoId, apiKey]);

  if (loading)
    return <p className="comentarioYoutube-loading">Carregando comentários...</p>;

  return (
    <div className="comentarioYoutube-container">
      {/* Instrução para o usuário - usando a mesma "área" que antes era do modal */}
      <div className="comentarioYoutube-instrucao">
        <p>
          Deixe seu comentário sobre as aulas! Para isso, você precisa abrir o
          vídeo diretamente no YouTube. Após comentar no YouTube, suas opiniões
          aparecerão automaticamente aqui abaixo.
        </p>
        <button
          className="comentarioYoutube-open-modal-button"
          onClick={abrirPaginaYouTube}
        >
          Abrir Vídeo no YouTube
        </button>
      </div>

      {/* Lista de comentários do YouTube */}
      <h3 className="comentarioYoutube-titulo">Comentários</h3>
      <ul className="comentarioYoutube-lista">
        {comentarios.map((comentario) => (
          <li key={comentario.id} className="comentarioYoutube-item">
            <div className="comentarioYoutube-conteudo">
              <img
                src={comentario.imagemPerfil}
                alt={`Foto de perfil de ${comentario.autor}`}
                className="comentarioYoutube-imagem"
              />
              <div className="comentarioYoutube-texto-container">
                <strong className="comentarioYoutube-autor">
                  {comentario.autor}
                </strong>
                <p
                  className="comentarioYoutube-texto"
                  dangerouslySetInnerHTML={{ __html: comentario.texto }}
                ></p>
                <span className="comentarioYoutube-data">
                  {comentario.data}
                </span>
              </div>
            </div>

            {/* Exibe as respostas (se existirem) */}
            {comentario.respostas.length > 0 && (
              <ul className="comentarioYoutube-respostas-lista">
                {comentario.respostas.map((resposta) => (
                  <li
                    key={resposta.id}
                    className="comentarioYoutube-resposta-item"
                  >
                    <img
                      src={resposta.imagemPerfil}
                      alt={`Foto de perfil de ${resposta.autor}`}
                      className="comentarioYoutube-resposta-imagem"
                    />
                    <div className="comentarioYoutube-resposta-texto-container">
                      <strong className="comentarioYoutube-resposta-autor">
                        {resposta.autor}
                      </strong>
                      <p
                        className="comentarioYoutube-resposta-texto"
                        dangerouslySetInnerHTML={{ __html: resposta.texto }}
                      ></p>
                      <span className="comentarioYoutube-resposta-data">
                        {resposta.data}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComentariosYoutube;
