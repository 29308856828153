import React, { useState, useEffect, useRef } from 'react';
import { 
  FaDownload, 
  FaCheckCircle, 
  FaLock, 
  FaHome, 
  FaList, 
  FaTimes, 
  FaStar, 
  FaComment 
} from 'react-icons/fa';

// Import do novo componente de comentários do YouTube
import ComentariosYoutube from './ComentariosYoutuber.js'; 

import './Treinamentos.css';

const Treinamento = () => {
  /**
   * NOVO STATE para pegar o nome do usuário
   */
  const [userName, setUserName] = useState('Visitante');

  // Nome fixo do módulo
  const [moduloNome] = useState('Contingência para prosperar');

  // Aulas fictícias (11 aulas)
  const aulasIniciais = [
    { 
      id: 1,
      nome: 'Aula 00 Base',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=2c1ecdf7-b4c9-4946-b163-4b89d830f5ea',
      descricao: 'Aula 01: Fundamentos',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/Facebook-Ads-B-sico-87e4be2714a24776bbcd107a5a2cbc89'
    },
    { 
      id: 2,
      nome: 'Aula 01: Fundamentos',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=c825ad70-85c9-4931-9f04-2e9284de268d',
      descricao: 'Aula 01: Fundamentos',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/Facebook-Ads-B-sico-87e4be2714a24776bbcd107a5a2cbc89'
    },
    { 
      id: 3,
      nome: 'Aula 02: O que é D.C.A?',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=d89f4611-ad58-47db-ba1f-e09144f87690',
      descricao: 'Aula 02: O que é D.C.A?',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/Conting-ncia-Para-Facebook-ADS-f336de270c854b54994218de8e9f2a80'
    },
    { 
      id: 4,
      nome: 'Aula 03: Criando um E-mail',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=ae59ead1-3971-474e-84d1-c447da526f4c',
      descricao: '* Aula 03: Criando um E-mail',
      link_arquivos: ''
    },
    { 
      id: 5,
      nome: 'Aula 04: Criando uma Conta no Dolphin-anty',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=0659ed1d-6473-4cf7-a03f-9f08d2ccdd4a',
      descricao: '* Aula 04: Criando uma Conta no Dolphin-anty',
      link_arquivos: 'https://dolphin-anty.com'
    },
    { 
      id: 6,
      nome: 'Aula 05: Criando uma Máquina Virtual',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=e81fc683-d3dd-4535-99a4-60ea21220608',
      descricao: '* Aula 05: Criando uma Máquina Virtual',
      link_arquivos: ''
    },
    { 
      id: 7,
      nome: 'Aula 06: Injetando Cookies',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=d1525cf5-d471-4e25-b1b4-0693623645ab',
      descricao: '* Aula 06: Injetando Cookies',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/100-SITES-MAIS-ACESSADO-PARA-USAR-NA-INJE-AO-16aa8c3c4a60800d9da4fe5918203b7b'
    },
    { 
      id: 8,
      nome: 'Aula 07: Conexão 4G',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=8eeecd57-7674-40a9-b275-60a2812cd55f',
      descricao: '* Aula 07: Conexão 4G',
      link_arquivos: ''
    },
    { 
      id: 9,
      nome: 'Aula 08: Criação do Perfil no Facebook Ads 4G',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=0dff87c1-5eac-4c2d-8b5d-7ec0230e4b10',
      descricao: '',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/Conting-ncia-Para-Facebook-ADS-f336de270c854b54994218de8e9f2a80?pvs=74'
    },
    { 
      id: 10,
      nome: 'Aula 09: Autenticação de 2 Fatores',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=4867a22c-16a5-4807-9e90-e91d57d1d317',
      descricao: 'Aula 09: Autenticação de 2 Fatores',
      link_arquivos: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=pt_BR'
    },
    { 
      id: 11,
      nome: 'Como Criar Uma Páginas.',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=73cc51f2-9217-4b5a-b2cb-227dbae37391',
      descricao: 'Como Criar Uma Páginas.',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/P-ginas-de-An-ncio-a66fe1e53bee46a0b1e6da709cb3302c'
    },
    { 
      id: 12,
      nome: 'Aula 11 Proxy (Criando Perfil no Facebook).',
      link_video: 'https://player-vz-4c2e3b6f-4b2.tv.pandavideo.com.br/embed/?v=9e941569-0be5-42ed-82df-9741e850b3c7',
      descricao: 'Aula 11 Proxy (Criando Perfil no Facebook).',
      link_arquivos: 'https://pattern-babcat-82e.notion.site/Proxy-d1df7220cfcd47fd9fc908cd2bbfdb4b'
    },
  ];

  const videoRef = useRef(null);

  // Estados principais
  const [aulas] = useState(aulasIniciais);
  const [videoAtual, setVideoAtual] = useState(aulasIniciais[0].link_video);
  const [aulaAtualId, setAulaAtualId] = useState(aulasIniciais[0].id);
  const [descricaoAula, setDescricaoAula] = useState(aulasIniciais[0].descricao);
  const [linkArquivo, setLinkArquivo] = useState(aulasIniciais[0].link_arquivos || '');
  const [aulaAtualNome, setAulaAtualNome] = useState(aulasIniciais[0].nome);

  // Comentários: alternar entre descrição e comentários
  const [showComentarios, setShowComentarios] = useState(false);

  // Progressão e conclusões
  const [aulasConcluidas, setAulasConcluidas] = useState([]);
  const [progresso, setProgresso] = useState(0);

  // Avaliação (estrelas) por aula
  const [avaliacao, setAvaliacao] = useState(0);
  // Aqui guardaremos { aulaId: nota, ... }
  const [avaliacoesAulas, setAvaliacoesAulas] = useState({});

  // Menu mobile: controla abertura/fechamento
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  /**
   * Ao montar o componente, 
   *  - Verificamos se 'nome' e 'email' estão no localStorage. Se não, redirecionar /#/Presente.
   *  - Caso sim, pegamos o nome para exibir no cabeçalho.
   *  - Depois carregamos progresso salvo localmente (se existir).
   */
  useEffect(() => {
    const nomeStorage = localStorage.getItem('nome');
    const emailStorage = localStorage.getItem('email');

    if (!nomeStorage || !emailStorage) {
      // Se não existir, redireciona
      window.location.hash = '/Presente';
      return;
    } else {
      // Se existir, atualiza estado com o nome
      setUserName(nomeStorage);
    }

    // Carrega o progresso do localStorage
    const dadosSalvos = localStorage.getItem('treinamento-progresso');
    if (dadosSalvos) {
      try {
        const parse = JSON.parse(dadosSalvos);
        setAulasConcluidas(parse.aulasConcluidas || []);
        setAvaliacoesAulas(parse.avaliacoesAulas || {});
        setProgresso(parse.progresso || 0);

        // Ajusta nota da aula atual caso ela já tenha sido avaliada
        const notaInicial = parse.avaliacoesAulas[aulaAtualId] || 0;
        setAvaliacao(notaInicial);
      } catch (error) {
        console.error('Erro ao carregar progresso do localStorage:', error);
      }
    }
  }, [aulaAtualId]);

  /**
   * Função para salvar no localStorage.
   */
  const salvarLocalStorage = (novasAulasConcluidas, novasAvaliacoesAulas, novoProgresso) => {
    const objetoSalvar = {
      aulasConcluidas: novasAulasConcluidas,
      avaliacoesAulas: novasAvaliacoesAulas,
      progresso: novoProgresso
    };
    localStorage.setItem('treinamento-progresso', JSON.stringify(objetoSalvar));
  };

  /**
   * Selecionar aula e atualizar estados correspondentes
   */
  const selecionarAula = (aula) => {
    if (!aula) return;
    setVideoAtual(aula.link_video);
    setAulaAtualId(aula.id);
    setDescricaoAula(aula.descricao);
    setAulaAtualNome(aula.nome);
    setLinkArquivo(aula.link_arquivos || '');

    // Avaliação da aula selecionada
    const notaAula = avaliacoesAulas[aula.id] || 0;
    setAvaliacao(notaAula);

    // Se estiver no mobile, ao clicar em uma aula, podemos fechar o menu
    setShowMobileMenu(false);
  };

  /**
   * Marca ou desmarca conclusão de aula + avança.
   */
  const handleAulaAction = () => {
    if (aulasConcluidas.includes(aulaAtualId)) {
      desmarcarAulaConcluida(aulaAtualId);
    } else {
      marcarComoConcluida(aulaAtualId);
      avancarParaProximaAula();
    }
  };

  const marcarComoConcluida = (idAula) => {
    const novasAulasConcluidas = [...aulasConcluidas, idAula];
    const novoProgresso = Math.round((novasAulasConcluidas.length / aulas.length) * 100);
    setAulasConcluidas(novasAulasConcluidas);
    setProgresso(novoProgresso);

    // Salvar no localStorage
    salvarLocalStorage(novasAulasConcluidas, avaliacoesAulas, novoProgresso);
  };

  const desmarcarAulaConcluida = (idAula) => {
    const novasAulasConcluidas = aulasConcluidas.filter((aid) => aid !== idAula);
    const novoProgresso = Math.round((novasAulasConcluidas.length / aulas.length) * 100);
    setAulasConcluidas(novasAulasConcluidas);
    setProgresso(novoProgresso);

    // Salvar no localStorage
    salvarLocalStorage(novasAulasConcluidas, avaliacoesAulas, novoProgresso);
  };

  /**
   * Avança para a próxima aula da lista
   */
  const avancarParaProximaAula = () => {
    const currentIndex = aulas.findIndex((a) => a.id === aulaAtualId);
    if (currentIndex < aulas.length - 1) {
      selecionarAula(aulas[currentIndex + 1]);
    }
  };

  /**
   * Avaliar aula (estrelas)
   */
  const avaliarAula = (nota) => {
    const novasAvaliacoes = { ...avaliacoesAulas, [aulaAtualId]: nota };
    setAvaliacao(nota);
    setAvaliacoesAulas(novasAvaliacoes);

    // Atualizar localStorage
    salvarLocalStorage(aulasConcluidas, novasAvaliacoes, progresso);
  };

  return (
    <>
      {/* ALERTA FIXO NO TOPO (acima do cabeçalho) */}
      <div className="treinamento-alerta-container">
        <p>
          <strong>Atenção:</strong> O treinamento estará disponível até
          <span style={{ marginLeft: '5px' }}> 02/01/2025 às 23:59</span>
        </p>
      </div>

      {/* CABEÇALHO FIXO (abaixo do alerta) */}
      <div className="treinamento-header">
        <div className="treinamento-header-logo">
          <img 
            src="../../imagens/logo_menu_login.webp" 
            alt="Logo" 
            className="treinamento-logo" 
          />
        </div>
        <div className="treinamento-header-user">
          {/* Botão "Home" fictício */}
          <button 
            className="modulo-home-btn"
            title="Ir para Menu Principal"
          >
            <FaHome />
          </button>
          {/* Substituir 'Visitante' pelo nome do usuário - DEIXAR VISÍVEL NO MOBILE */}
          <span className="treinamento-user-name">Olá, {userName}</span>
        </div>
      </div>

      <div className="treinamento-content">
        <div className="treinamento-main">
          <div className="treinamento-gamificacao">

            {/* Seção do Vídeo */}
            <div className="treinamento-video-section">
              {videoAtual && (
                <>
                  <iframe
                    ref={videoRef}
                    src={videoAtual}
                    title="Aula"
                    className="treinamento-video-frame"
                    allowFullScreen
                  />
                  <h3 className="treinamento-aula-titulo">{aulaAtualNome}</h3>
                </>
              )}
              <div className="treinamento-video-controls">
                <button 
                  onClick={handleAulaAction} 
                  className="treinamento-button"
                >
                  {aulasConcluidas.includes(aulaAtualId) 
                    ? 'Desmarcar conclusão da aula'
                    : 'Marcar como concluído e avançar'}
                </button>
              </div>

              {/* Avaliação (estrelas) */}
              <div className="treinamento-avaliacao">
                {[1, 2, 3, 4, 5].map((estrela) => (
                  <FaStar
                    key={estrela}
                    className={`treinamento-star ${estrela <= avaliacao ? 'treinamento-star-active' : ''}`}
                    onClick={() => avaliarAula(estrela)}
                  />
                ))}
              </div>
            </div>

            {/* Botão "Ver Aulas" (menu mobile) - ABAIXO do vídeo no mobile */}
            <button
              className="treinamento-mobile-menu-toggle"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              {showMobileMenu ? <FaTimes /> : <FaList />}
              Ver Aulas
            </button>

            {/* Descrição / Comentários */}
            <div className="treinamento-description">
              <div className="treinamento-description-header">
                <button 
                  className={`treinamento-tab-button ${!showComentarios ? 'active' : ''}`}
                  onClick={() => setShowComentarios(false)}
                >
                  Descrição da Aula
                </button>
                <button 
                  className={`treinamento-tab-button ${showComentarios ? 'active' : ''}`}
                  onClick={() => setShowComentarios(true)}
                >
                  <FaComment /> Comentários
                </button>
              </div>

              {showComentarios ? (
                // Ao clicar em "Comentários", exibimos o componente do YouTube
                <ComentariosYoutube />
              ) : (
                <>
                  <p>{descricaoAula}</p>
                  {linkArquivo && linkArquivo.trim() !== '' && (
                    <a 
                      href={linkArquivo} 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="treinamento-download-link"
                    >
                      <FaDownload /> Material complementar
                    </a>
                  )}
                </>
              )}
            </div>

            {/* Sidebar com lista de aulas e progresso */}
            <div 
              className={`treinamento-sidebar ${
                showMobileMenu ? 'treinamento-sidebar-mobile-active' : ''
              }`}
            >
              {/* Botão de fechar menu no mobile (exibido apenas se showMobileMenu=true) */}
              {showMobileMenu && (
                <button
                  className="treinamentos-vertical-menu-close"
                  onClick={() => setShowMobileMenu(false)}
                >
                  <FaTimes />
                </button>
              )}

              {/* Progresso */}
              <div className="treinamento-progress">
                <h3>Progresso do Módulo</h3>
                <div className="treinamento-progress-bar">
                  <div 
                    className="treinamento-progress-fill"
                    style={{ width: `${progresso}%` }}
                  />
                </div>
                <span className="modulo-progress-text">
                  {progresso}% Concluído
                </span>
              </div>

              {/* Lista de Aulas */}
              <div className="treinamento-aulas-container">
                <div className="treinamento-modulo-header">
                  <h3>{moduloNome}</h3>
                </div>
                <div className="treinamento-aulas-lista">
                  <ul>
                    {aulas.map((aula) => (
                      <li
                        key={aula.id}
                        onClick={() => selecionarAula(aula)}
                        className={`
                          treinamento-aula-item 
                          ${videoAtual === aula.link_video ? 'treinamento-aula-atual' : ''} 
                          ${aulasConcluidas.includes(aula.id) ? 'treinamento-aula-concluida' : ''}
                        `}
                      >
                        {aulasConcluidas.includes(aula.id) ? (
                          <FaCheckCircle className="treinamento-aula-icon" />
                        ) : (
                          <FaLock className="treinamento-aula-icon" />
                        )}
                        <span>{aula.nome}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>{/* Fim Sidebar */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Treinamento;
